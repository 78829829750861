


/* { 
  name: '',
  website: '',
  logo: '',
  score: ,
  columns: ['', 'X', '',  '', '',''],
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
}, */

 const developmentAds  = [
      { 
        name: 'General Protocols',
        website: 'generalprotocols.com',
        logo: 'generalprotocols.png',
        description: 'We build open source protocols to empower the next generation of innovators. Includes BCHBull, Anyhedge, Flipstarter.',
        socialHandles: ['t.me/GeneralProtocols','twitter.com/GeneralProtocol','www.reddit.com/user/GeneralProtocols','www.youtube.com/@generalprotocols','youtube.com/@BitcoinCashFoundation'],
        videos: [],
        longerDescription: 'Established as a company in 2019, General Protocols prides itself on developing permissionless, trustless, borderless financial solutions for the whole world. We are a leading developer in the Bitcoin Cash ecosystem and working at the frontier of UTXO blockchain development.',
        tags:[],
      },
      { 
        name: 'Cash Improvement Proposals',
        website: 'gitlab.com/im_uname/cash-improvement-proposals/-/blob/master/CHIPs.md?ref_type=heads',
        logo: 'nologo.png',
        description: 'The CHIPs is the public documentation of a shared ecosystem process to achieve one positive change on the BCH network . ',
        socialHandles: ['t.me/GeneralProtocols','read.cash/@GeneralProtocols/a-focus-on-the-bitcoin-cash-ecosystem-08-chips-f020b9bc','bitcoincashresearch.org/t/chips-a-more-detailed-process-recommendation/309/25'],
        videos: ['https://www.youtube.com/embed/9rB83MocRrQ'],
        longerDescription: 'For a completely decentralized network, such as Bitcoin Cash, having a robust process for upgrading the network is of huge benefit to all stakeholders.',
        tags:[],
      },
      { 
        name: 'Bitcoincash Research',
        website: 'bitcoincashresearch.org/',
        logo: 'bchresearch.png',
        description: 'Vision The Bitcoin Cash ecosystem requires a common space where pioneers, innovators and developers can collaborate productively.',
        socialHandles: [],
        videos: [],
        longerDescription: '',
        tags:[],
      },
      { 
        name: 'Bitcoin Cash Foundation',
        website: 'bitcoincashfoundation.org',
        logo: 'bchf.png',
        description: 'Non-official someones focused on outreach and promotion of Bitcoin Cash as p2p electronic cash for the world. ',
        socialHandles: ['twitter.com/BCHF_ORG','t.me/BitcoinCashFoundation','t.me/BCHFNews','publish0x.com/bitcoin-cash2','medium.com/@BCHF_ORG,','read.cash/@BCHF','tiktok.com/@bchf_org'],
        videos: [],
        longerDescription: 'Someone needs to do something! The BCHF brings to you weekly news on the BCH ecosystem, keeps Coinmarketcap news and info upto date,  and more! ',
        tags:[],
      },
  
      { 
        name: 'Simple Ledger Protocol',
        website: 'slp.dev/',
        logo: 'slp.png',
        description: 'Simple Ledger Protocol developed ontop of the Bitcoin Cash blockchain. ',
        socialHandles: ['t.me/simpleledger'],
        videos: [],
        longerDescription: '',
        tags:[],
      },
    ]
export default developmentAds