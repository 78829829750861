import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import styled from 'styled-components'
import Header from './components/Header';
import FAQ from './Pages/FAQ';
import Home from './Pages/Home';
import Start from './Pages/GettingStarted'
import Events from './Pages/Events'
import Footer from './components/Footer';
import Submissions from './Pages/Submissions';
import GridGame from './Pages/GridGame'
import GettingStarted from './Pages/GettingStarted';


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/gettingstarted" element={<GettingStarted />} />
          <Route path="/events" element={<Events />} />
          <Route path="/canvas" element={<GridGame />} />
          <Route path="/Submissions" element={<Submissions />} />

        </Routes>
      </div>
      <Footer />
    </Router>
  );
}
  
  export default App;