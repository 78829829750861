import React, { useState, useEffect, useRef } from 'react';
import { Canvas, CanvasRef } from './components/Canvas';
import styled from 'styled-components';
import QRcode from '../../components/QRcode';
import BigPic from '../../assets/img/casualLogo.png'
import TrollBox from '../../components/Trollbox'

interface ChangedCell {
  x: number;
  y: number;
  color: string;
}

const GridGame = () => {
  const [paymentAddress, setPaymentAddress] = useState<string>('');
  const [uuid, setUuid] = useState<string>('');
  const [fee, setFee] = useState<bigint>(BigInt(0));
  const canvasRef = useRef<CanvasRef>(null);
  const [ws, setWs] = useState<WebSocket | null>(null); //websocket holder

  const handleServerAcceptChanges = (changedCells: ChangedCell[]): Promise<void> => {
    return new Promise((resolve, reject) => {
      //fetch('http://localhost:3004/api/grid/payment', {
      fetch('https://helpme.cash/api/grid/payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ changes: changedCells }),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Server response not OK');
        }
        return response.json();
      })
      .then(data => {
        if (data.received) {
          setPaymentAddress(data.address);
          setUuid(data.id);
          setFee(data.satoshis);
          //console.log('Pay ' + data.satoshis + ' sats to: ' + data.address + ' uuid: ' + data.id);
          resolve(); // Resolve the promise if the server confirms the changes, implicit 'true'
        } else {
          reject(new Error('Payment not received'));  // Server rejected the change, promise will be error.
        }
      })
      .catch(error => {
        console.error('Error handling server payment:', error);
        reject(error);
      });
    });
  };

//////////////////////////////////////////  
/////  Close Payment modal
//////////////////////////////////////////
  const handleClearQR = () => {
    setPaymentAddress('');
    setUuid('');
    setFee(BigInt(0));
  };
//////////////////////////////////////////  
/////  Setup websocket connection to server
//////////////////////////////////////////
  useEffect(() => {
    //const newWs = new WebSocket('ws://localhost:3004');
    const newWs = new WebSocket('wss://helpme.cash/api');
    setWs(newWs);
  
    newWs.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'colorChange' && canvasRef.current) {
        canvasRef.current.updateCellColors(message.data, message.address);
      }
    };
  
    return () => {
      newWs.close();
    };
  }, []);

  return (
    <AppContainer>
      <BackgroundDiv />
      <PageContainer>
        <StyledCanvasContainer>
          <Canvas 
            initialWidth={700} 
            initialHeight={700} 
            onAcceptChanges={handleServerAcceptChanges}
            ref={canvasRef}
          />
        </StyledCanvasContainer>

{/* <TrollBox ws={ws}/> */}
        {paymentAddress !== '' && (
          <StyledQR>
            <StyledAddress>Publishing should occur within ~10 seconds</StyledAddress>
            <StyledAddress>(No feedback on successful payment yet)</StyledAddress>
            Pay {Number(fee)} sats ({(Number(fee) / 100000000).toFixed(8)} BCH)
            <QRcode address={paymentAddress} clearQR={handleClearQR} satoshis={fee}/>
            <StyledAddress>{paymentAddress}</StyledAddress><br />
            <StyledAddress>ID: {uuid}</StyledAddress>
          </StyledQR>
        )}

      </PageContainer>
    </AppContainer>
  );
};

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: top;
  height: 100%; // Take the full viewport height
  border: 0px solid pink;
`;
const BackgroundDiv = styled.div`
  background-image: url(${BigPic});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 155px;
  display: flex;
  height: 100%;
  max-height: 816px;
  width: 100%;
  border: 0px solid red;
  z-index: -1;
  opacity: 0.4;
`;
const PageContainer = styled.div`
  color: #fff;
  border: 0px solid #0AC18E;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  @media (max-width: 768px) { /* For tablets and smaller devices */
  max-width: 768px;
}
@media (max-width: 480px) { /* For mobile devices */ 
  max-width: 480px;
}
`;
const StyledCanvasContainer = styled.div`
  border: 0px solid orange;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  position: relative;

  @media (max-width: 768px) { /* For tablets and smaller devices */
  max-width: 768px;
  }
  @media (max-width: 480px) { /* For mobile devices */ 
    max-width: 480px;
  }
`;
const StyledQR = styled.div`
  background: transparent;
  background-size: cover;
  position: absolute;
  left: 35%;
  top: 0%;
  width: 450px;
  height: 400px;
  background-color: #fff;
  border: 4px solid #0AC18E;
  border-radius: 30px 30px 30px 30px;
  font-size: 20px;
  font-weight: 700;
  padding: 1px;
  color: #000;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;
const StyledAddress = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #000;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export default GridGame;