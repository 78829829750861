import React, { useState, ReactNode} from 'react';
import styled, { keyframes } from 'styled-components'
import Modal from '../Modal'


const darken = (hex: string, percent: number): string => {
  if (!hex) return '';
  let num = parseInt(hex.replace("#", ""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) - amt,
      B = (num & 0x0000FF) - amt,
      G = (num >> 8 & 0x00FF) - amt;

  return "#" + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (G < 255 ? G < 1 ? 0 : G : 255) * 0x100 + (B < 255 ? B < 1 ? 0 : B : 255)).toString(16).slice(1);
};



type TagColors = {
  [key: string]: string;
};

const tagColors: TagColors = {
  'cashtokens': '#76B041', // Example color for CashTokens
  'nsfw': '#B22222',
  'beta': '#009CC8',
  'spanish': '#8962FF',
  'mandarin': '#B66600',
  'german': '#FFCC00'
  // Add more tag types and their colors here  #B60000  B22222
};

const getTagColor = (tag: string): string => {
  console.log(`Tag: '${tag}'`); // Log the tag to debug
  const color = tagColors[tag.toLowerCase().trim()] || '#0AC18E';
  console.log(`Color: '${color}'`); // Log the color to debug
  return color;
};



interface Ad {
  name?: string;
  website?: string;
  logo?: string;
  description?: string;
  socialHandles: string[];
  videos?: string[]; 
  longerDescription?: string; 
  tags?:string[];  
}

interface CardProps {
  children?: ReactNode;
  data: Ad;
}



  const Card: React.FC<CardProps> = ({ data }) => {
    const [modalVisible, setModalVisible] = React.useState(false);
  
    const openModal = () => {
      //setModalVisible(true);
    };
  
    const closeModal = () => {
     // setModalVisible(false);
    };





    return (
      <StyledAd> 
        {data.logo ? <StyledLogo src={require(`../../assets/logos/${data.logo}`)} alt={data.website} /> : null }
        <StyledWebsite href={`https://${data.website}`} target="_blank" rel="noopener noreferrer">{data.name}</StyledWebsite>
        <StyledDescription>{data.description}</StyledDescription>
          
        <TagsContainer>
  {data.tags?.map((tag, index) => {
    const baseColor = getTagColor(tag.trim());
    return (
      <StyledTag key={index} baseColor={baseColor || '#0AC18E'}>
        {tag}
      </StyledTag>
    );
  })}
</TagsContainer>
          
        {modalVisible && <Modal onClose={closeModal} data={data} />}
      </StyledAd>
    );
  };


const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledAd = styled.div`
  background-color: black;
  border: 1px solid #00cc66;
  margin: 1px auto;
  padding: 1px;
  color: #F8F8F8; // main text white instead of bright white
  height: 120px;
  width: 400px;
  max-width: 400px;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Center horizontally */
  position: relative; /* Add position relative to contain absolute child */
  border-radius: 40px 40px 0px 40px;
  &:hover {
    background-color: #021d15;
  }
`;
const StyledLogo = styled.img`
  position: absolute;
  top: -8px;
  left: -8px;
  width: 75px;
  height: 75px;
  border-radius: 180px 180px 180px 180px;
  border: 2px solid #0AC18E;
  background-color: black;
  &:hover {
    animation: ${spin} 5s linear infinite;  /* Apply the spinning animation */
  }
`;
const StyledWebsite = styled.a`
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  left: 80px;
  top: 0px;
  margin-top: 10px;
  text-decoration: none;
  color: #FAEBE5;
  &:hover {
    color: #0AC18E;
  }
`;
const StyledDescription = styled.p`
  position: absolute;
  left: 20px;
  margin-top: 50px;
  text-indent: 50px;
  text-align: left;
`;
const SocialLinksContainer = styled.div`
  display: flex;
  gap: 4px; /* Adjust the gap between links */
  font-size: 12px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3px;
  justify-content: center;
`;

const StyledTag = styled.span<{ baseColor?: string }>`
  background-image: ${props => props.baseColor
    ? `linear-gradient(to bottom, ${props.baseColor}, ${darken(props.baseColor, 40)})`
    : 'none'};
  color: #F8F8F8;
  padding: 1px 3px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 600;
  margin-right: 5px;
  margin-bottom: 2px;
  white-space: nowrap;
  border: 1.8px solid #00cc66;
`;


const TagsContainer = styled.div`
  position: absolute;
  top: -4px; // Adjust this value to position the tags higher on the card
  right: 22px; // Adjust if you want more spacing from the right edge
  display: flex;
  flex-direction: row; // This will layout tags in a row
  flex-wrap: wrap; // Allow wrapping to next line
  align-items: flex-start;
  justify-content: flex-end; // Align tags to the right

`;

export default Card