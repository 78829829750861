import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
//import HelpMeLogo from '../../assets/img/helpmelogo.png';
import HelpMeLogo from '../../assets/img/cashcat150.png';
import Chat from '../Chat';

interface MyWindow extends Window {
  googleTranslateElementInit: () => void;
}
declare let window: MyWindow;

declare namespace google {
  namespace translate {
    class TranslateElement {
      constructor(options: any, container: string);
    }
  }
}

function Header() {
    // Use state to track the current page if needed
    const [currentPage, setCurrentPage] = useState('Home');
  
    // Function to toggle the current page if needed
    const togglePage = (page: string) => {
      setCurrentPage(page);
    };

useEffect(() => {
  if (!document.querySelector('script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]')) {
    const googleTranslateScript = document.createElement('script');
    googleTranslateScript.type = 'text/javascript';
    googleTranslateScript.async = true;
    googleTranslateScript.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    (document.getElementsByTagName('head')[0] || document.documentElement).appendChild(googleTranslateScript);
  }
  window.googleTranslateElementInit = function() {
    new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
  };
}, []);

/*  <StyledLink to="/gettingstarted" onClick={() => togglePage('Getting Started')}>
<MenuButton isactive={currentPage === 'Getting Started'}>Getting Started</MenuButton>
</StyledLink> */

    return (
      <StyledHeader>
        <Chat />
        <StyledGroup>
          <StyledLink to="/">
            <StyledTitle>HelpMe.<span className="cash">Cash</span></StyledTitle>
          </StyledLink>

          <StyledMenu>
            <StyledLink to="/" onClick={() => togglePage('Home')}>
              <MenuButton isactive={currentPage === 'Home'}>Home</MenuButton>
            </StyledLink>
            <MenuButton onClick={() => window.open('https://discover.cash/', '_blank', 'noopener,noreferrer')}>
              Getting Started
            </MenuButton>
         
            <StyledLink to="/faq" onClick={() => togglePage('FAQ')}>
              <MenuButton isactive={currentPage === 'FAQ'}>FAQ</MenuButton>
            </StyledLink>
   
            <StyledLink to="/events" onClick={() => togglePage('Events')}>
              <MenuButton isactive={currentPage === 'Events'}>Events</MenuButton>
            </StyledLink>

            <StyledLink to="/canvas" onClick={() => togglePage('Canvas')}>
              <MenuButton isactive={currentPage === 'Canvas'}>Canvas</MenuButton>
            </StyledLink>

          </StyledMenu>

        </StyledGroup>

        <StyledLogo />

        <StyledTranslate>
            <div id="google_translate_element"></div>
        </StyledTranslate>

    </StyledHeader>
);
}


interface MenuButtonProps {
  isactive?: boolean;
}

const StyledLink = styled(Link)`
  text-decoration: none;
`;
const StyledHeader = styled.div`
  display: flex;
  background-color: black;
  color: #F8F8F8;
  padding: 10px;
  text-align: center;
  font-size: 24px;
  border-bottom: 1px solid #0AC18E;
  height: 135px;
  justify-content: center;
`
const StyledGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const StyledTitle = styled.div`
  color: #F8F8F8;
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  .cash {
    color: #0AC18E;
  }
`
const StyledLogo= styled.div`
  background-image: url(${HelpMeLogo});
  text-align: center;
  width: 130px;
  height: 150px;
  position: relative;
  top: 4px;
  z-index: 5;
`
const StyledMenu = styled.div`
  display: flex;
  position: relative;
  left: 5px;
  gap: 5px;
  @media (max-width: 832px) {
    font-size: 16px;
  }
`;
const StyledTranslate = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  right: 15px;
  z-index: 15;
`
const MenuButton = styled.button<MenuButtonProps>`
  background-color: ${(props) => (props.isactive ? '#09af81' : '#000')};
  color: #F8F8F8;
  padding: 5px 5px;
  border: 1px solid #0AC18E;
  border-radius: 0px;
  font-size: 18px;
  cursor: pointer;
  &:hover {
    background-color: #004295;
  }
`;
const MenuButtonTeaser = styled.button<MenuButtonProps>`
  background-color: #000;
  color: #F8F8F8;
  padding: 5px 5px;
  border: 1px solid #0AC18E;
  border-radius: 0px;
  font-size: 18px;
  cursor: not-allowed;
  &:hover {
    background-color: #a00;
  }
`;
//delete?
const GettingStartedButton = styled.button`
  background-color: #0AC18E;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: inherit;
  &:hover {
    background-color: #0056b3;
  }
  &:active {
    background-color: #004295;
  }
`;
const FAQButton = styled.button`
  background-color: #0AC18E;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: inherit;
  &:hover {
    background-color: #0056b3;
  }
  &:active {
    background-color: #004295;
  }
`;

const HomeButton = styled.button`
  background-color: #0AC18E;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: inherit;
  &:hover {
    background-color: #0056b3;
  }
  &:active {
    background-color: #004295;
  }
`;

const EventsButton = styled.button`
  background-color: #0AC18E;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: inherit;
  &:hover {
    background-color: #0056b3;
  }
  &:active {
    background-color: #004295;
  }
`;


export default Header;