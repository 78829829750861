import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

interface CountdownTimerProps {
  targetDate: string; // Accept a target date as a prop
  eventName: string; // Accept an event name as a prop
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ targetDate, eventName }) => {
  // Convert the target date from string to Date object
  const target = new Date(targetDate);

  // State to store the countdown
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // Update the countdown every second
    const interval = setInterval(() => {
      const now = new Date();
      const difference = target.getTime() - now.getTime();

      if (difference > 0) {
        // Calculate time left
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        // Update state with the new countdown
        setCountdown({ days, hours, minutes, seconds });
      } else {
        // Clear countdown when the event date is reached
        clearInterval(interval);
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [target]);

  return (
    <div>
      <EventName>{eventName}</EventName>
      <CountdownContainer>
        <TimeUnitContainer>
          <TimeCircle>{countdown.days}</TimeCircle>
          <TimeLabel>Days</TimeLabel>
        </TimeUnitContainer>
        <TimeUnitContainer>
          <TimeCircle>{countdown.hours}</TimeCircle>
          <TimeLabel>Hours</TimeLabel>
        </TimeUnitContainer>
        <TimeUnitContainer>
          <TimeCircle>{countdown.minutes}</TimeCircle>
          <TimeLabel>Minutes</TimeLabel>
        </TimeUnitContainer>
        <TimeUnitContainer>
          <TimeCircle>{countdown.seconds}</TimeCircle>
          <TimeLabel>Seconds</TimeLabel>
        </TimeUnitContainer>
      </CountdownContainer>
    </div>
  );
};

const EventName = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px; // Adjust the space between each unit as needed
`;

const TimeUnitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TimeCircle = styled.div`
  border: 2px solid #000; // Change color as needed
  border-radius: 50%;
  width: 100px; // Adjust size as needed
  height: 100px; // Adjust size as needed
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em; // Adjust font size as needed
`;

const TimeLabel = styled.span`
  margin-top: 5px;
  font-size: 1em; // Adjust label font size as needed
  text-transform: uppercase;
`;

export default CountdownTimer;
