import React, { useState, ReactNode} from 'react';
import styled from 'styled-components'


interface Ad {
  name?: string;
  website?: string;
  logo?: string;
  description?: string;

  videos?: string[]; 
  longerDescription?: string; 
}

interface SocialCardProps {
  children?: ReactNode;
  data: Ad;
}
  
  const SocialCard: React.FC<SocialCardProps> = ({ data }) => {

    return (
      <StyledAd> 
        {data.longerDescription ? <StyledType src={require(`../../assets/logos/${data.longerDescription}`)} /> : null }
        {data.logo ? <StyledLogo src={require(`../../assets/logos/${data.logo}`)} /> : null }
        <StyledWebsite href={`https://${data.website}`} target="_blank" rel="noopener noreferrer">{data.name}</StyledWebsite>
        <StyledDescription>{data.description}</StyledDescription>
      </StyledAd>
    );
  };

const StyledAd = styled.div`
  background-color: black;
  border: 1px solid #00cc66;
  margin: 1px auto;
  margin-right: 15px;
  padding: 1px;
  color: white;
  height: 60px;
  width: 250px;
  max-width: 250px;
  min-width: 200px;
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Center horizontally */
  position: relative; /* Add position relative to contain absolute child */
  border-radius: 40px 40px 0px 40px;
  &:hover {
    background-color: #021d15;
  }
`;
const StyledType = styled.img`
  position: absolute;
  top: 0px;
  left: -15px;
  width: 35px;
  height: 35px;
  border-radius: 180px 180px 180px 180px;
  border: 2px solid #0AC18E;
  background-color: black;
  z-index: 11;
`;
const StyledLogo = styled.img`
  position: absolute;
  top: 15px;
  left: 0px;
  width: 50px;
  height: 50px;
  border-radius: 180px 180px 180px 180px;
  border: 2px solid #0AC18E;
  background-color: black;
  z-index: 12;
`;
const StyledWebsite = styled.a`
  font-weight: bold;
  font-size: 16px;
  position: absolute;
  left: 45px;
  top: -10px;
  margin-top: 10px;
  text-decoration: none;
  color: #fff;
  &:hover {
    color: #0AC18E;
  }
`;
const StyledDescription = styled.p`
  position: absolute;
  left: 65px;
  top: -30px;
  font-size: 14px;
  margin-top: 50px;
  text-indent: 0px;
  text-align: left;
`;
const SocialLinksContainer = styled.div`
  display: flex;
  gap: 4px; /* Adjust the gap between links */
  font-size: 12px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3px;
  justify-content: center;
`;

export default SocialCard