/* { 
  name: '',
  website: '',
  logo: '',
  score: ,
  columns: ['', 'X', '',  '', '',''],
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
  tags:[],
}, */

const walletsAds  = [
    { 
      name: 'Selene',
      website: 'Selene.cash',
      logo: 'selene.png',
      description: 'Selene Wallet puts easy, no-hassle, instant payments in the palm of your hand. By Jeremy and Kalisti.',
      socialHandles: ['t.me/SeleneWallet', 'twitter.com/SeleneWallet', 'git.xulu.tech/selene.cash/selene-wallet/', 'www.youtube.com/watch?v=5RqI6tByySQ', 'play.google.com/store/apps/details?id=cash.selene.app'],
      videos: ['https://www.youtube.com/embed/5RqI6tByySQ'],
      longerDescription: 'Selene Wallet puts easy, no-hassle, instant payments in the palm of your hand. Experience the future of money; a global digital economy powered by Bitcoin Cash (BCH).',
      tags:[],
    },
    { 
      name: 'Stackwallet',
      website: 'stackwallet.com',
      logo: 'stackwallet.png',
      description: 'The first open-source, non-custodial, privacy-preserving cryptocurrency wallet for all crypto fans',
      socialHandles: ['www.youtube.com/channel/UCqCtpXsLyNIle1uOO2DU7JA', 't.me/stackwallet', 'twitter.com/stack_wallet', 'github.com/cypherstack/stack_wallet', 'www.reddit.com/r/stackwallet/', 'discord.gg/mRPZuXx3At'],
      videos: [],
      longerDescription: 'The first open-source, non-custodial, privacy-preserving cryptocurrency wallet for all crypto fans',
      tags:[],
    },
    { 
      name: 'Bitcoin.com',
      website: 'Bitcoin.com',
      logo: 'bitcoincom.png',
      description: 'Buy, sell, store, send & receive cryptocurrency with the Bitcoin.com Wallet, trusted by millions. Easy-to-use, non-custodial digital wallet. Android and iOS.',
      socialHandles: ['www.youtube.com/channel/UCetxkZolEBHX47BqtZktbkg', 't.me/www_Bitcoin_com', 'twitter.com/bitcoincom', 'www.facebook.com/buy.bitcoin.news', 'www.instagram.com/bitcoin.com_official/'],
      videos: [],
      longerDescription: 'Buy, sell, store, send & receive cryptocurrency with the Bitcoin.com Wallet, trusted by millions. Easy-to-use, non-custodial digital wallet. Android and iOS.',
      tags:[],
    },
    { 
      name: 'Electron Cash',
      website: 'ElectronCash.org',
      logo: 'electroncash.png',
      description: ' A Bitcoin Cash SPV Wallet. Control your own private keys. Easily back up your wallet with a mnemonic seed phrase. Suports CashTokens',
      socialHandles: ['wiki.electroncash.de/wiki/Main_Page','www.youtube.com/playlist?list=PL6P_87NPp72aMQeOiSO4Pq8u_yeOcY6K7', 'github.com/Electron-Cash/Electron-Cash', 't.me/electroncashwallet'],
      videos: [],
      longerDescription: `Make sure to visit the wiki page linked above for more information! \n 
      A Bitcoin Cash SPV Wallet. Control your own private keys. Easily back up your wallet with a mnemonic seed phrase. Enjoy high security without downloading the blockchain or running a full node. Also has a SLP-tokens enabled version. Windows, OSX, Linux, Android, iOS.`,
      tags:['CashTokens'],
    },
    { 
      name: 'Paytaca',
      website: 'Paytaca.com',
      logo: 'paytaca.png',
      description: 'The Paytaca wallet is our primary vehicle in bringing Bitcoin Cash to everyone, everywhere. Suports CashTokens',
      socialHandles: ['github.com/paytaca'],
      videos: ['https://www.youtube.com/embed/5Hg6hecDl8U'],
      longerDescription: `Bitcoin Cash (BCH) is a decentralized digital currency designed for online transactions, enabling direct peer-to-peer payments. It serves as a reliable form of electronic cash for the modern internet era.
      \n
      The Paytaca wallet makes this groundbreaking cryptocurrency accessible to individuals worldwide. Our wallet is available as a user-friendly application for browsers, mobile devices, and desktops, catering to both novice and experienced users.
      \n
      By leveraging the Paytaca wallet, users gain access to the full potential of BCH, with exclusive control over their private keys. This level of control ensures complete ownership and empowers individuals to have full authority over their funds.
      \n
      Paytaca facilitates the development of a peer-to-peer economy by harnessing BCH's fast transaction speeds, low fees, and programmability. With near-instantaneous transfers and cost-effective fees, Paytaca enables seamless online payments, encouraging widespread adoption and supporting microtransactions.
      \n
      Leveraging BCH's programmable features, Paytaca also empowers users to engage in decentralized finance (DeFi) applications and unlock the innovative potential of the cryptocurrency, paving the way for a more inclusive and dynamic financial landscape`,
      tags:['CashTokens'],
    },
    { 
      name: 'Zapit',
      website: 'Zapit.io',
      logo: 'zapit.png',
      description: 'Zapit is a Bitcoin Cash wallet supporting CashTokens, and SLP Tokens with non-custodial peer-to-peer services.',
      socialHandles:['t.me/zapit_io','linktr.ee/zapit','twitter.com/zapit_io','discord.com/invite/qv24FrZRBE','github.com/zapit-io','youtube.com/channel/UC73ftzCuewJJv5p5wPSWyuw','instagram.com/zapit.io/','reddit.com/user/zapit_io','facebook.com/zapit.io/','medium.com/@zapit_io'],
      videos: [],
      longerDescription: ` Zapit is a Bitcoin Cash wallet supporting CashTokens- both BCMR and Crc20 tokens, and SLP Tokens with non-custodial peer-to-peer services powered by a non-custodial escrow.`,
      tags:['CashTokens'],
    },
    { 
      name: 'Coinomi',
      website: 'Coinomi.com',
      logo: 'coinomi.png',
      description: 'Securely store, manage and exchange Bitcoin, Ethereum, and more than 1,770 other blockchain assets.',
      socialHandles: ['www.youtube.com/playlist?list=PL6P_87NPp72aMQeOiSO4Pq8u_yeOcY6K7', 't.me/coinomi_official', 'twitter.com/coinomiwallet', 'www.reddit.com/r/COINOMI/', 'www.instagram.com/coinomi_com/', 'www.facebook.com/coinomi/'],
      videos: [],
      longerDescription: 'The first open-source, non-custodial, privacy-preserving cryptocurrency wallet for all crypto fans',
      tags:[],
    },
    { 
      name: 'Bitpay',
      website: 'Bitpay.com',
      logo: 'bitpay.png',
      description: 'Manage and spend your Bitcoin with the BitPay App. Android and iOS.',
      socialHandles: ['twitter.com/BitPay'],
      videos: [],
      longerDescription: 'Manage and spend your Bitcoin with the BitPay App. Android and iOS.',
      tags:[],
    },
    { 
      name: 'Blockchain',
      website: 'Blockchain.com',
      logo: 'blockchaincom.png',
      description: 'The world’s most popular crypto wallet. Over 77 million wallets created to buy, sell, and earn crypto.',
      socialHandles: ['medium.com/blockchain/'],
      videos: [],
      longerDescription: 'The world’s most popular crypto wallet. Over 77 million wallets created to buy, sell, and earn crypto.',
      tags:[],
    },
    { 
      name: 'BTC.com',
      website: 'BTC.com',
      logo: 'btccom.png',
      description: 'The world’s most popular crypto wallet. Over 77 million wallets created to buy, sell, and earn crypto.',
      socialHandles: ['medium.com/blockchain/', 'twitter.com/btccom_official', 'www.instagram.com/blockchainofficial/'],
      videos: [],
      longerDescription: 'The world’s most popular crypto wallet. Over 77 million wallets created to buy, sell, and earn crypto.',
      tags:[],
    },
    { 
      name: 'Cashaddress',
      website: 'Cashaddress.org',
      logo: 'cashaddress.jpg',
      description: 'Open Source JavaScript Client-Side Bitcoin Cash PaperWallet Generator.',
      socialHandles: [],
      videos: [],
      longerDescription: 'Open Source JavaScript Client-Side Bitcoin Cash PaperWallet Generator.',
      tags:[],
    },{
      name: 'Flowee Pay',
      website: 'flowee.org/products/pay',
      logo: 'flowee.png',
      description: 'A secure and also beautiful wallet exclusively for BitcoinCash',
      socialHandles: ['t.me/Flowee_org,twitter.com/FloweeTheHub'],
      videos: [],
      longerDescription: `Flowee Pay has security and privacy from the roots up. This is unique, most other wallets take the shortcuts that allow it to innovate faster but depend on the company behind it having servers. This gives them all your private data, payment history and more. 
      \nFlowee Pay does not use such servers, does not leak private data that can be sold and we can not be forced to lock your coins. Ever.

        \n Flowee Pay goes even further than that. Indeed, there is no Flowee server. There is no data collection on our side. \n
      We also go through lengths to make sure that any random server on the Internet can’t piece together your private data. For example, in the wallet you can create different accounts and those are kept completely separate. Separate for usage, but also separate for the rest of the Internet.`,
      tags:['CashTokens'], 
    },

    
  ]

export default walletsAds