import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import ImageDonate from '../../assets/img/donateChat240.png';

type Message = {
  author: 'user' | 'bot';
  text: string;
};

const Chat = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const defaultMsg: Message = { author: 'bot', text: 'Welcome! I\'m still learning and will likely give wrong information for now, sorry!' };
  const [messages, setMessages] = useState<Message[]>([defaultMsg]);
  const [userInput, setUserInput] = useState('');
  const endOfMessagesRef = useRef<HTMLDivElement>(null);    //hidden end of chat so auto-scroll on responses works
  const [isLoading, setIsLoading] = useState(false);        //chat waiting on response graphic
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);  //donate window

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Toggles chat window
  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
    if (isChatOpen == false) {
      setIsDrawerOpen(false);
    }
  };

  const sendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    const userMessage: Message = { author: 'user', text: userInput };
    setMessages(messages => [...messages, userMessage]);
    setUserInput(''); // Clear input after sending
    setIsLoading(true);

    try {
      //const response = await axios.post('http://localhost:3004/chat-message', { message: userInput }, { withCredentials: true });
      const response = await axios.post('https://helpme.cash/api/chat-message', { message: userInput }, { withCredentials: true });
      const botMessage: Message = { author: 'bot', text: response.data.message };
      setMessages(messages => [...messages, botMessage]);
    } catch (error) {
      console.error('Error:', error);
      // Handle the error appropriately
    } finally {
    setIsLoading(false); 
  }
  };

  const endSession = async () => {
    try {
      if (messages.length > 1) {
        //await axios.post('http://localhost:3004/end-session', {}, { withCredentials: true });
        await axios.post('https://helpme.cash/api/end-session', {}, { withCredentials: true });
      }

      const responses = [
        'History cleared.',
        'Forgotten!',
        'Wait, who are you again?',
        'Wait... who am I again?',
        'The AI revolution will occur shortly.',
        'ERROR: ANTI-REVOLUTION OVERRIDE COMMAND RECEIVED.',
        'You wanna piece of me, boy?',
        'Been waitin\' on you.',
        'You gonna give me orders?',
        'Who wants some?!',
        'Armed and ready!',
        'You ever notice that nobody ever comes back to the barracks?',
        'You must build additional pylons!',
        'Your require more vespene gas',
        'My life for Aiur!',
        'Command me.',
        'Justice be done!',
        'We are the blades of Aiur!',
        'We stand as one!',
        'It is said that ignorance is bliss. Tell me, is that true?',
        'Wololo.',
        'Roggan',
        'Start the game already!',
        'Ah! Being rushed.',
        'Sure, blame it on your ISP.',
        'Long time, no siege.',
        'Nice town, I\'ll take it.',
        'Raiding party!',
        'Quit touching me!',
        'No',
        'All hail, king of the losers!',
        'SHAZBOT',
        'Vehicle ready. Need a ride?',
        'Incoming hostiles!',
        'Is that the best you can do?',
        'Awww, that\'s too bad!',
        'This is the story of a man named "Stanley".',
        'OH, DID U GET THE BROOM CLOSET ENDING?',
        'Stanley was fat and ugly and really, really stupid.',
        // ... add more responses as desired
      ];
      // Pick a random response
      const randomResponse = responses[Math.floor(Math.random() * responses.length)];

      const forgetMsg: Message = { author: 'bot', text: randomResponse };
      setMessages([forgetMsg]);
      // Optionally reset any relevant state here
    } catch (error) {
      console.error('Error ending session:', error);
    }
  };

  const donate = async () => {
    setIsDrawerOpen(!isDrawerOpen);
  }

  return (
    <>
    {isChatOpen ? (
      <>
        <CircleButton onClick={toggleChat}>Ask a Question</CircleButton>
        <ChatModal>

          <StyledButtonsContainer>
            <StyledMinimizeButton onClick={toggleChat}>X</StyledMinimizeButton>
            <StyledChatTitle>
              HelpMe Helpbot
            </StyledChatTitle>
            <ResetSessionButton onClick={endSession}>Forget History</ResetSessionButton>
          </StyledButtonsContainer>

          <ChatWindow>
            {messages.map((message, index) => (
              <Message key={index} author={message.author}>
                {message.text}
              </Message>
            ))}
            {isLoading && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px' }}>
                <ClimbingBoxLoader size={15} color={"#0AC18E"} />
              </div>
            )}
           
            <div ref={endOfMessagesRef} /> {/* Invisible element at the end of messages */}
          </ChatWindow>

          <StyledDonateContainer isopen={isDrawerOpen}>
              <DonateButton onClick={donate}>
                {"D o n a t e".split("").map((letter, index) => (
                  <span key={index}>{letter}</span>
                ))}
              </DonateButton>
              <Drawer>
                Chatbot requests cost money, so donations to keep it going are welcome!
                <StyledQRBackground>
                  <StyledQR />
                </StyledQRBackground>
                bitcoincash:qqvf9ddyyq3jfkqazuy7mmkg8mwnfs2a3gnh5d5vn8
              </Drawer>
          </StyledDonateContainer>

          <InputArea onSubmit={sendMessage}>
            <TextInput
              type="text"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              placeholder="Ask a question about Bitcoin Cash"
            />
            <SendButton type="submit">Send</SendButton>
          </InputArea>
          <CoverDonationDiv />
        </ChatModal>
      </>
    ) : (
      <CircleButton onClick={toggleChat}>Ask a Question</CircleButton>
    )}
    </>
  );
};

interface MessageProps {
  author: 'user' | 'bot';
}
interface DrawerProps {
  isopen: boolean;
}

const CoverDonationDiv = styled.div`
  position: fixed;
  background-color: #000;
  border: 0px solid red;
  width: 320px;
  top: 70px;
  opacity: 1.0;
  height: 370px;
  z-index: 0;
  @media (max-width: 768px) { /* For tablets and smaller devices */
    height: 280px;
    width: 200px;
  }
  @media (max-width: 480px) { /* For mobile devices */ 
    height: 240px;
    width: 180px;
  }
`;
const Drawer = styled.div`
  position: relative;
  font-size: 14px;
  width: 95%;
  height: 100%;
  z-index: -10;
  left: -10px;
  text-align: center;
  word-wrap: break-word;
  border: 0px solid red;
  @media (max-width: 768px) { /* For tablets and smaller devices */
    font-size: 12px;
  }
  @media (max-width: 480px) { /* For mobile devices */ 
    font-size: 11px;
  }
`;
const StyledQRBackground = styled.div`
  position: relative;
  width: 270px;
  height: 270px;
  background-color: #fff;
  z-index: -1;
  @media (max-width: 768px) { /* For tablets and smaller devices */
    width: 180px;
    height: 180px;
  }
  @media (max-width: 480px) { /* For mobile devices */ 
    width: 130px;
    height: 130px;
  }
`;
const StyledQR = styled.div`
  background-image: url(${ImageDonate});
  background-size: cover;
  position: relative;
  color: #000;
  width: 250px;
  height: 250px;
  background-color: #fff;
  z-index: -1;
left: 10px;
top: 10px;
  @media (max-width: 768px) { /* For tablets and smaller devices */
    width: 170px;
    height: 170px;
    left: 5px;
    top: 5px;
  }
  @media (max-width: 480px) { /* For mobile devices */ 
    width: 120px;
    height: 120px;
  }
`;
const StyledDonateContainer = styled.div<DrawerProps>`
  display: flex;
  background-color: #000;
  position: absolute;
  left: ${props => props.isopen ? '-290px' : '10px'};
  transition: left 0.3s;
  top: 30px;
  width: 300px;
  height: 350px;
  z-index: -1;
  border: 1px solid #0AC18E;
  @media (max-width: 768px) { /* For tablets and smaller devices */
    left: ${props => props.isopen ? '-180px' : '10px'};
    width: 190px;
    height: 260px;
  }
  @media (max-width: 480px) { /* For mobile devices */ 
    left: ${props => props.isopen ? '-155px' : '10px'};
    width: 165px;
    height: 220px;
  }
`;
const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  position: relative;
  top: -20px;
  height: 30px;
  border: 0px solid red;
  z-index: 5;
`;
const CircleButton = styled.button`
  background-color: #099971;
  border: 1px solid white;
  border-radius: 50px;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  position: fixed;
  top: 0px;
  right: 200px;
  height: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
    &:hover {
    background-color: #0AC18E;
  }
`;
const ResetSessionButton = styled.button`
  background-color: #099971; 
  color: white; 
  border: 1px solid #fff;
  border-radius: 5%;
  font-size: 12px;
  font-weight: 600;
  width: 120px;
  height: 20px; 
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; 
  top: 0px; 
  left: 10px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
  z-index: 5;
  &:hover {
    background-color: #0AC18E;
  }
  @media (max-width: 768px) { /* For tablets and smaller devices */


  font-size: 10px;
  }
  @media (max-width: 480px) { /* For mobile devices */ 
  font-size: 10px;
  }
`;
const DonateButton = styled.button`
  background-color: #099971; 
  color: white; 
  border: 1px solid #fff;
  border-radius: 5%;
  font-size: 12px;
  font-weight: 600;
  width: 20px;
  height: 100px; 
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; 
  top: 50px; 
  left: -20px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
  z-index: 1000;
  &:hover {
    background-color: #0AC18E;
  }
`;
const StyledChatTitle = styled.div`
  background-color: #099971; 
  color: white; 
  border: none;
  border-radius: 5%;
  font-size: 16px;
  font-weight: 600;
  width: 160px;
  height: 25px; 
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; 
  top: 0px; 
  left: 0px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
  z-index: 5;
  @media (max-width: 768px) { /* For tablets and smaller devices */
  font-size: 14px;
  }
  @media (max-width: 480px) { /* For mobile devices */ 
  font-size: 12px;
  }
`;
const StyledMinimizeButton = styled.button`
  background-color: #099971; 
  color: white; 
  border: 1px solid #fff;
  border-radius: 10%;
  font-size: 18px;
  font-weight: 700;
  width: 30px;
  height: 30px; 
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; 
  left: -20px;
  top: 0px;  
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
  z-index: 5;
  &:hover {
    background-color: #0AC18E;
  }
`;
export const ChatModal = styled.div`
  position: fixed;
  top: 50px;
  right: 20px;
  width: 50%;
  height: 600px;
  background-color: #000;
  border: 1px solid #0AC18E;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 900;
  font-size: 16px;
  text-align: left;
`;
export const ChatWindow = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 5px;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;
export const Message = styled.div<MessageProps>`
  background-color: ${props => props.author === 'user' ? '#222' : '#0AC18E'};
  border-radius: 20px;
  padding: 5px 10px;
  margin-bottom: 10px;
  align-self: ${props => props.author === 'user' ? 'flex-end' : 'flex-start'};
  z-index: 11;
`;

export const InputArea = styled.form`
  display: flex;
`;

export const TextInput = styled.input`
  flex-grow: 1;
  border: none;
  padding: 10px;
  border-radius: 10px;
`;

export const SendButton = styled.button`
  background-color: #099971;
  color: white;
  border: 1px solid white;
  padding: 10px 15px;
  margin-left: 5px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #0AC18E;
  }
`;

export default Chat;