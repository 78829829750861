import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal'


export interface Ad {
    name?: string;
    website?: string;
    logo?: string;
    score: number;
    columns: (string | number)[]; 
    description?: string;
    socialHandles: string[];
    videos?: string[]; 
    longerDescription?: string;
  }
  
export interface ExchangeData {
  name: string;
  logo?: string;
  columns: (string | number)[];
}

interface ExchangeTableProps {
  data: Ad[];
 // onExchangeClick: (data: ExchangeData) => void;
}


const ExchangeTable: React.FC<ExchangeTableProps> = ({ data }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedExchange, setSelectedExchange] = useState<Ad | null>(null);
  

  
    const handleExchangeClick = (exchange: Ad) => {
      setSelectedExchange(exchange);
      setIsModalOpen(true);
    };
  return (
    <>
    <TableWrapper>
      <Header>
        <Logo>Logo</Logo>
        <ExchangeName>Exchanges</ExchangeName>
        
        {/* Add header titles as needed */}
        <CellGroup>
            <CellTitle data-label="CEX or DEX Or P2P" />
            <CellTitle data-label="CashTokens: addresses" />
            <CellTitle data-label="bitcoincash: addresses" />
            <CellTitle data-label="Deposit Confirmations" />
        </CellGroup>
      </Header>
      {data.map((exchange) => (
            <Row key={exchange.name} onClick={() => handleExchangeClick(exchange)}>
       <LogoImage><img src={require(`../../assets/logos/${exchange.logo}`)} alt={`${exchange.name} logo`} /></LogoImage>
          <ExchangeEntry>{exchange.name}</ExchangeEntry>
          {exchange.columns.map((col, idx) => (
            <Cell key={idx}>{col}</Cell>
          ))}
     </Row>
        ))}
      </TableWrapper>
  
      {isModalOpen && selectedExchange && (
        <Modal
          onClose={() => setIsModalOpen(false)}
          data={selectedExchange}
        />
      )}
    </>
  );
};

const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 0px solid red;
    font-family: Arial, sans-serif;
    font-size: 14px;
    background-color: transparent;
    border-collapse: collapse;
`;
const Header = styled.div`
    border: 0px solid red;
    display: flex;
    flex-direction: row;
    height: 100px;
    padding: 0px 0px;
`;
const CellGroup = styled.div`
    display: flex;
    flex-direction: row;
    border: 0px solid yellow;
    width: 440px;
    height: 100px;
    padding: 0px 0px;
`;
const CellTitle = styled.div`
    flex: 0 1 40px;
    height: 100px;
    width: 20px;
    position: relative;
    border: 0px solid #0AC18E;
    text-align: center;
    font-size: 13px;
    font-weight: 300;
    z-index: 0;
  left: 85px;

    &:before {
        content: '';
        position: absolute;
        top: 100px;
        left: 10px;
        width: 325%; /* Increase width to cover the entire cell diagonally */
        height: 32px;
        background: linear-gradient(to right bottom, #0AC18E, #000); /* Adjust the colors as needed */
        transform: skewY(-45deg);
        transform-origin: top left;
        opacity: 0.7;
    }

    &:after {
        content: attr(data-label);
        position: absolute;
        top: 90%; /* Center the label vertically */
        left: 40px; /* Center the label horizontally */
        transform: rotate(-45deg) translateY(-50%);
        transform-origin: left bottom;
        white-space: nowrap;
        color: white;
        z-index: 1; /* Make sure the label is on top of the pseudo-element */
    }

`;
const Row = styled.div`
    display: flex;
    padding: 0px 0px;
    background-color: #000;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
    &:hover ${CellTitle} {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;
const ExchangeName = styled.div`
    display: flex;
    align-items: flex-end;
    flex: 0 1 500px;
    text-align: center;
    justify-content: center;
    color: white;
    padding: 0px;
    font-weight: 700;
    border: 0px solid red;
`;
const ExchangeEntry = styled.div`
    flex: 0 1 500px;
    text-align: left;
    color: white;
    border-bottom: 1px solid #0AC18E;
    padding: 5px;
    &:hover {
        background-color: #0056b3;
        border-bottom: 1px solid #fff;
    }
`;
const DiagonalHeader = styled.div`
    flex: 1;
    height: 50px;
    position: relative;
    overflow: hidden;
    text-align: center;
    &:before {
        content: attr(data-label);
        position: absolute;
        top: 100%;
        left: 0;
        transform: rotate(-45deg) translateY(-50%);
        transform-origin: left bottom;
        white-space: nowrap;
        color: white;
    }
`;
const Cell = styled.div`
    flex: 0 1 40px;
    text-align: center;
    width: 50px;
    color: white;
    border: 1px solid #000;
    background-color: #0AC18E;
    padding: 5px;
    z-index: 1;
`;
const Logo = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 70px;
    color: white;
    border: 0px solid #000;
    font-weight: 700;
`;
const LogoImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px; 
    height: 23px; 
    border-radius: 50%; 
    border: 2px solid #0AC18E;
    background-color: black;
    overflow: hidden; 
    img {
        width: 100%; 
        height: 100%; 
        object-fit: cover; 
        border-radius: 50%; 
    }
`;
export default ExchangeTable;