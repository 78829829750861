/* { 
  name: '',
  website: '',
  logo: '',
  score: ,
  columns: ['', 'X', '',  '', '',''],
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
}, */



 const NFTsAds =[
    { 
      name: 'BCH Gurus',
      website: 'bch.guru/',
      logo: 'bchguru.png',
      description: ' A unique, on-chain, peer-to-peer crypto price prediction game and NFT collection, built on Bitcoin Cash mainchain.',
      socialHandles: ['t.me/bch_guru','twitter.com/BCH_Guru','youtube.com/@BCH_Guru','reddit.com/user/bch_guru','.instagram.com/bch_guru/'],
      videos: ['https://www.youtube.com/embed/T_kWyoetnHE'],
      longerDescription: 'The BCH Gurus are a pioneering cashtokens NFT collection on Bitcoin Cash, designed to integrate with the BCH Guru Game ecosystem. So come on, join in and see if you have what it takes to become a BCH guru! ',
      tags:[],
    },
    { 
      name: 'Cash Ninjas',
      website: 'ninjas.cash',
      logo: 'cashninja.png',
      description: 'an NFT collection leveraging CashTokens technology on Bitcoin Cash with OpenAI enhancements for each Cash-Ninja! ',
      socialHandles: ['t.me/cashninjas','twitter.com/cashninjasbch','www.youtube.com/@CashNinjasBCH','github.com/cashninjas'],
      videos: ['https://www.youtube.com/embed/zhSRhdrKeLM'],
      longerDescription: 'The project is the result of a few BCH supporters crossing paths and teaming-up to build something awesome together. Then releasing open source tools for all the builders out there!',
      tags:[],
    },
    { 
      name: 'Bitcats Heroes Club',
      website: 'bitcatsheroes.club/',
      logo: 'bitcatshero.png',
      description: 'BitCats Heroes Club is an NFT collection that uses the CashTokens protocol in Bitcoin Cash.',
      socialHandles: ['twitter.com/BitCatsHeroes','t.me/BitCatsHeroesClub'],
      videos: [],
      longerDescription: 'This comprises of 10K unique hand-drawn digital images of cats.',
      tags:[],
    },
    { 
      name: 'Emerald DAO',
      website: 'emerald-dao.vercel.app/',
      logo: 'emeralddao.png',
      description: 'Emerald DAO is the first NFT Cash Tokens! Where users lock there funds and recieved and NFT in return',
      socialHandles: ['t.me/emeralddao','twitter.com/EmeraldDaoNFT'],
      videos: [],
      longerDescription: '',
      tags:[],
    },
    { 
      name: 'Slime Shadies',
      website: 'shadybattlegrounds.xyz',
      logo: 'slimeshadies.png',
      description: 'From a racing game to the full-scale player versus-player artillery game that includes several weapon types to take onto the battlefield!',
      socialHandles: ['twitter.com/SlimeShadies','discord.com/invite/WbTuz78TsB'],
      videos: [],
      longerDescription: 'Create and level your battle snail for increased health, shield, and energy. Change the looks of your snails on the battlegrounds with unique custom skins and trade your in-game assets.',
      tags:[],   
    },
  ]

export default NFTsAds