import React, {useState} from 'react';
import styled from 'styled-components';

interface Category {
    name: string;
    questions: { question: string; answer: string; answerVisible: boolean }[]; // Add answerVisible property
  }

interface SidebarProps {
    categories: Category[];
    onCategoryClick: (category: Category) => void;
  }
 
  
  
  const SideBar: React.FC<SidebarProps> = ({ categories, onCategoryClick }) => {
    const [activeCategory, setActiveCategory] = useState<Category | null>(null);
            
    const handleCategoryClick = (category: Category) => {
      setActiveCategory(category);
      onCategoryClick(category);
      
    };

    return (
        <SidebarContainer>
          <StyledTitle>Categories</StyledTitle>

            <StyledButtons>
              {categories.map((category, index) => (
                <ButtonListItem
                  key={index}
                  onClick={() => handleCategoryClick(category)}
                  className={category === activeCategory ? 'active' : ''}
                >
                  {category.name}
                </ButtonListItem>
              ))}
            </StyledButtons>

        </SidebarContainer>
      );
    };
    

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 210px; /* Adjust the width as needed */
  height: 100%; /* This will make it extend the length of the page */
  top: 0px;
  background-color: black; /* Customize the background color */
  color: #fff; /* Text color */
  border-right: 1px solid #0AC18E;
  justify-content: center;
`;
const StyledTitle = styled.div`
  position: fixed;
  top: 170px;
  left: 45px;
  background-color: black; /* Customize the background color */
  color: #fff;
  font-size: 24px;
  font-weight: 650;
  padding: 5px; /* Padding for content inside the sidebar */
  border: 0px solid red;
  justify-content: center;
`;
const StyledButtons = styled.div`
  position: fixed;
  top: 210px;
  left: 20px;
  border: 0px solid red;
  justify-content: center;
  width: 150px;
`;
const ButtonListItem = styled.div`
  cursor: pointer;
  position: relative;
  justify-content: center;
  width: 100%;
  background-color: #000;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #0AC18E;
  border-radius: 5px; /* Rounded corners for the button */
  transition: background-color 0.3s;
  &:hover {
    background-color: #0AC18E; /* Background color on hover */
  }
  &.active {
    background-color: #0AC18E; /* Solid color for the active button */
  }
`;




export default SideBar;
