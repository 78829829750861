import React, { useState, ReactNode } from 'react';
import styled from 'styled-components'
import QRCode from 'react-qr-code';
import ImageClose from '../../assets/img/close.png';
import ImageCloseHighlight from '../../assets/img/closeHighlight.png';

interface QRcodeProps {
  clearQR: () => void;
  address: string;
  satoshis: bigint;
}

const QRcode: React.FC<QRcodeProps> = ({ address, clearQR, satoshis }) => {
  const [isVisible, setIsVisible] = useState(true);
  const amount = parseFloat((Number(satoshis) / 100000000).toFixed(8));
  console.log('qrcode amount: ' + amount);
  const handleClose = () => {
    setIsVisible(false);
    clearQR();
  };

  return (
    <StyledContainer>
      <QRCode value={`${address}?amount=${amount}`} /> {/* amount in format 0.005 */}
      <StyledCloseButton onClick={handleClose} />
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
`;
const StyledCloseButton = styled.img`
  position: absolute;
  top: -8px;
  right: -8px;
  width: 75px;
  height: 45px;
  background-image: url(${ImageClose});
  background-size: cover;
  &:hover {
    background-image: url(${ImageCloseHighlight});
  }
`;
const StyledWebsite = styled.a`
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  left: 80px;
  top: 0px;
  margin-top: 10px;
  text-decoration: none;
  color: #fff;
  &:hover {
    color: #0AC18E;
  }
`;
const StyledDescription = styled.p`
  position: absolute;
  left: 20px;
  margin-top: 50px;
  text-indent: 50px;
  text-align: left;
`;
const SocialLinksContainer = styled.div`
  display: flex;
  gap: 4px; /* Adjust the gap between links */
  font-size: 12px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3px;
  justify-content: center;
`;

export default QRcode