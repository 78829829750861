import React, { useRef, useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import ImageEraser from '../../../../assets/img/eraser.png'

interface CanvasProps {
  initialWidth: number;
  initialHeight: number;
  onAcceptChanges: (changedCells: ChangedCell[]) => Promise<void>;
}
interface ChangedCell {
  x: number;
  y: number;
  color: string;
}
interface ChangeHistoryEntry {
  address: string;
  changes: ChangedCell[];
}

export type CanvasRef = {
  updateCellColors: (changes: ChangedCell[], address: string) => void;
};

const baseCost = 2000; // Cost for the first cell
const minCost = 500;   // Cost for the 50th cell and beyond
const maxCellsForDiscount = 50; // Cells to reach minimum cost

const Canvas = forwardRef<CanvasRef, CanvasProps>(( props, ref ) => {
  const { initialWidth, initialHeight, onAcceptChanges } = props;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [hoverCell, setHoverCell] = useState<{ x: number, y: number } | null>(null);
  const [clickedCell, setClickedCell] = useState<{ x: number, y: number } | null>(null);
  const [changedCells, setChangedCells] = useState<ChangedCell[]>([]); //track cell color changes user makes
  const [isRequestPending, setIsRequestPending] = useState(false);
  const { totalCost, nextCellCost, discountPercentage } = calculateCosts(changedCells.length);
  const [backupCellColors, setBackupCellColors] = useState<ChangedCell[]>([]); //for when user is changing cells, keep backup of cells original value
  const [isHistoryExpanded, setIsHistoryExpanded] = useState(false);
  const [colorPickerPosition, setColorPickerPosition] = useState({ x: 0, y: 0, show: false });
  const [selectedColor, setSelectedColor] = useState('#FF0000'); // Default color
  const [isEraserActive, setIsEraserActive] = useState(false);
  const cellSize = 10;

  const [changeHistory, setChangeHistory] = useState<ChangeHistoryEntry[]>([]); //for list of changes from users
  const [cellColors, setCellColors] = useState<string[][]>(
    Array.from({ length: initialHeight / cellSize }, () => 
      Array(initialWidth / cellSize).fill('white')) // Fill with default color
  );


///////////////////^ Variables ^////////////////////////////////////

const handleDraw = (x: number, y: number, clientX: number, clientY: number) => {
  setColorPickerPosition({ x: clientX, y: clientY, show: true });
};

const handleColorChange = (event: any) => {
  setSelectedColor(event.target.value);
  setColorPickerPosition({ ...colorPickerPosition, show: false });
  // You can also apply the selected color to the cell here
};


  //Function to update cell colors from live server websocket updates
  const updateCellColors = (changes: ChangedCell[], address: string) => {
console.log('update received');
    //Add address+changes to changeHistory for display in <StyledChanges>
    setChangeHistory(prevHistory => [
      ...prevHistory,
      { address, changes }
    ]);

    //Apply the color changes to the grid
    setCellColors(prevCellColors => {
      // Create a deep copy of the 2D array
      const newCellColors = prevCellColors.map(row => [...row]);
  
      // Apply each change to the copied array
      changes.forEach(change => {
        const { x, y, color } = change;
        newCellColors[y][x] = color;
      });
  
      return newCellColors;
    });
  };

  //Expose  these functions in this component to parent component (GridGame.tsx)
  useImperativeHandle(ref, () => ({
    updateCellColors
  }));



  // Function to draw the grid
  const drawGrid = (ctx: CanvasRenderingContext2D) => {
    for (let x = 0; x < initialWidth; x += cellSize) {
      for (let y = 0; y < initialHeight; y += cellSize) {
        // Use the color stored in cellColors for each cell
        ctx.fillStyle = cellColors[y / cellSize][x / cellSize];
        ctx.fillRect(x, y, cellSize, cellSize);
  
        // Default cell border
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 1;
        ctx.strokeRect(x, y, cellSize, cellSize);
      }
    }

    // Apply hover effect
    if (hoverCell) {
      ctx.fillStyle = '#adfae4'; // Light grey with some transparency
      ctx.fillRect(hoverCell.x * cellSize, hoverCell.y * cellSize, cellSize, cellSize);
    }

  
    // Highlight the clicked cell with a red border
    if (clickedCell) {
      ctx.strokeStyle = 'red';
      ctx.lineWidth = 2;
      ctx.strokeRect(clickedCell.x * cellSize, clickedCell.y * cellSize, cellSize, cellSize);
    }
  };

//////////////////////////////////////////
/////// Watch mouse position for hover effect
//////////////////////////////////////////
  const handleMouseMove = useCallback((event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;
  
      const gridX = Math.floor(mouseX / cellSize);
      const gridY = Math.floor(mouseY / cellSize);
  
      setHoverCell({ x: gridX, y: gridY });

      const ctx = canvas.getContext('2d');
      if (ctx && cellColors[gridY] && cellColors[gridY][gridX] !== undefined) {
        // Redraw only the hover effect
        // Clear the previous hover effect
        ctx.clearRect(gridX * cellSize, gridY * cellSize, cellSize, cellSize);

        // Redraw the cell with its stored color
        ctx.fillStyle = cellColors[gridY][gridX];
        ctx.fillRect(gridX * cellSize, gridY * cellSize, cellSize, cellSize);

        // Apply new hover effect
        ctx.fillStyle = 'rgba(211, 211, 211, 0.5)';
        ctx.fillRect(gridX * cellSize, gridY * cellSize, cellSize, cellSize);

        // Redraw the cell border
        ctx.strokeStyle = 'black';
        ctx.strokeRect(gridX * cellSize, gridY * cellSize, cellSize, cellSize);

        // Redraw the border of the clicked cell if necessary
        if (clickedCell) {
          ctx.strokeStyle = 'red';
          ctx.lineWidth = 2;
          ctx.strokeRect(clickedCell.x * cellSize, clickedCell.y * cellSize, cellSize, cellSize);
        }
      }
    }
  }, [cellSize, cellColors, clickedCell]);

//////////////////////////////////////////
/////// Click to select cell
//////////////////////////////////////////
  const handleMouseClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (canvasRef.current) {
      const rect = canvasRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
    
      const gridX = Math.floor(x / cellSize);
      const gridY = Math.floor(y / cellSize);

      handleDraw(gridX, gridY, event.clientX, event.clientY);

      //selected cell border glow
      setClickedCell({ x: gridX, y: gridY });
     
    };
  }
  
//////////////////////////////////////////
/////// Double-click to change cell color
//////////////////////////////////////////
  const handleDoubleClick = useCallback((event: React.MouseEvent<HTMLCanvasElement>) => {
    if (canvasRef.current) {
      const rect = canvasRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
    
      const gridX = Math.floor(x / cellSize);
      const gridY = Math.floor(y / cellSize);
    
      if (isEraserActive) {

        // Find the original color from backupCellColors
        const originalCell = backupCellColors.find(cell => cell.x === gridX && cell.y === gridY);
        if (originalCell) {
          // Reset the cell's color in cellColors to its original state
          setCellColors(prevColors => {
            const newColors = prevColors.map(row => [...row]);
            newColors[gridY][gridX] = originalCell.color;
            return newColors;
          });
        }

        // Remove cell from changedCells and backupCellColors
        setChangedCells(prev => prev.filter(cell => cell.x !== gridX || cell.y !== gridY));
        setBackupCellColors(prev => prev.filter(cell => cell.x !== gridX || cell.y !== gridY));
      } else {

      // Backup the original state of the changed cell
      setBackupCellColors(prevBackup => {
        const existingBackup = prevBackup.find(cell => cell.x === gridX && cell.y === gridY);
        if (!existingBackup) {
          return [...prevBackup, { x: gridX, y: gridY, color: cellColors[gridY][gridX] }];
        }
        return prevBackup;
      });

      // Update the color of the double-clicked cell
      setCellColors(prevColors => {
        const newColors = prevColors.map(row => [...row]);
        newColors[gridY][gridX] = selectedColor; // Use the state that holds the currently selected color
        return newColors;
      });

      // If cell already exists in changedCells[], update its color; otherwise, add new cell
      setChangedCells(prev => {
        const existingCellIndex = prev.findIndex(cell => cell.x === gridX && cell.y === gridY);
        if (existingCellIndex >= 0) {
          const newCells = [...prev];
          newCells[existingCellIndex] = { x: gridX, y: gridY, color: selectedColor };
          return newCells;
        } else {
          return [...prev, { x: gridX, y: gridY, color: selectedColor }];
        }
      });
    }
    }
  }, [selectedColor, cellSize, cellColors, isEraserActive]);

//////////////////////////////////////////
/////// User sending changed cell colors to server
//////////////////////////////////////////
  const handleAcceptChanges = () => {
    if (changedCells.length > 0 && !isRequestPending) {  //only do if the user has actually changed a cell
      setIsRequestPending(true);

      onAcceptChanges(changedCells) //send changes to GridGame.tsx handleServerAcceptChanges()
      .then(() => { //on promise true
        setChangedCells([]); // Clear users changed cells after successful server response
      })
      .catch(error => { //on promise false
        console.error('Error in accepting changes:', error);
      })
      .finally(() => {  //set to false when request completes
        setIsRequestPending(false); 
      });
    }
  };
//////////////////////////////////////////
/////// StyledInfoBox: values
//////////////////////////////////////////
function calculateCosts(numCells: number) {
  let totalCost = 0;
  let nextCellCost = 0;
  let discountPercentage = 0;

  for (let i = 0; i <= numCells; i++) {
    let costPerCell = Math.max(minCost, baseCost - ((baseCost - minCost) / (maxCellsForDiscount - 1)) * i);
    if (i < numCells) {
      totalCost += costPerCell;
    } else {
      // Calculate the next cell cost and discount percentage
      nextCellCost = costPerCell;
      discountPercentage = ((baseCost - nextCellCost) / baseCost) * 100;
    }
  }

  // Round the discount percentage
  discountPercentage = Math.round(discountPercentage);

  return { totalCost, nextCellCost, discountPercentage };
}
//////////////////////////////////////////
/////// Reset users color changes
//////////////////////////////////////////
const handleResetChanges = () => {
  //restore cells original values from backupCellColors to cellColors
  setCellColors(prevColors => {
    const newColors = prevColors.map(row => [...row]);
    backupCellColors.forEach(cell => {
      newColors[cell.y][cell.x] = cell.color;
    });
    return newColors;
  });

  //erase the arrays used to track user changes
  setBackupCellColors([]);
  setChangedCells([]);
};
//////////////////////////////////////////
/////// Change History window expanding on click
//////////////////////////////////////////
const toggleExpand = () => {
  setIsHistoryExpanded(prev => !prev);
};

  //initial page load: fetch cell colors from server
  useEffect(() => {
    //fetch('http://localhost:3004/api/grid/colors')
    fetch('https://helpme.cash/api/grid/colors')
      .then(response => response.json())
      .then(data => {
        setCellColors(data); // Assuming the server returns a 2D array of colors
      })
      .catch(error => console.error('Error fetching grid colors:', error));
  }, []);

  //initial page load to draw grid
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, initialWidth, initialHeight);
        drawGrid(ctx);
      }
    }
  }, []); // Empty dependency array to run only once on mount

  
  useEffect(() => {
    const canvas = canvasRef.current;
      if (canvas) {
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.clearRect(0, 0, initialWidth, initialHeight);
          drawGrid(ctx);
        }
      }
  }, [initialWidth, initialHeight, hoverCell, clickedCell, cellColors]);



  return (
    <StyledColumns>
      <StyledCanvasWrapper>
        <StyledInfoBox>

          <StyledInfoBackground />
          <StyledGroup>
            <StyledItem>Cells changed: <span style={{ color: '#0AC18E' }}>{changedCells.length}</span></StyledItem>
            <StyledItem>Cost: <span style={{ color: '#0AC18E' }}>{(Number(totalCost) / 100000000).toFixed(8)} BCH</span> </StyledItem>
          </StyledGroup>

          <StyledGroup>
            <StyledItem>Next cell: <span style={{ color: '#0AC18E' }}>{Math.floor(nextCellCost)} sats</span></StyledItem>
            <StyledItem><span style={{ color: '#0AC18E' }}>({discountPercentage}% discount)</span></StyledItem>
          </StyledGroup>

          <StyledToolsDiv>
          <StyledColorPicker
            type="color"
            value={selectedColor}
            onChange={handleColorChange}
          />
          <StyledEraser onClick={() => setIsEraserActive(!isEraserActive)} active={isEraserActive}/>
        </StyledToolsDiv>

          <StyledButtonDiv>
            <StyledButtons onClick={() => handleResetChanges()} type={'reset'}>Reset</StyledButtons>
            <StyledButtons onClick={handleAcceptChanges} disabled={isRequestPending} type={'publish'}>Publish Changes</StyledButtons>
          </StyledButtonDiv>

        </StyledInfoBox>

        <StyledCanvas
          ref={canvasRef}
          width={initialWidth}
          height={initialHeight}
          onDoubleClick={handleDoubleClick}
          onClick={handleMouseClick}
          onMouseMove={handleMouseMove}
        />
      </StyledCanvasWrapper>

      <StyledChangesContainer>
        <StyledChanges>
        <StyledTitle>CHANGES</StyledTitle>
          {changeHistory.slice().reverse().map((entry, index) => (
            <div key={index}>
              <span style={{ color: '#0AC18E' }}>{entry.address.substring(12,15)}...{entry.address.slice(-6)}</span> changed {entry.changes.length} cells
            </div>
          ))}
        </StyledChanges>
      </StyledChangesContainer>

    </StyledColumns>
  );
});

interface StyledButtonsProps {
  type: string;
}
interface StyledChangesContainerProps {
  expanded: boolean;
}
interface StyledEraserProps {
  active: boolean;
}

const StyledColumns = styled.div`
  display: flex;
  flex-direction: row;
  border: 0px solid red;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center; 
`;
const StyledCanvasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 0px solid green;
`;
const StyledCanvas = styled.canvas`
  background-color: #fff;
  border: 0px solid red;
`;
const StyledInfoBox = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  border: 0px solid red;
  width: 100%;
  height: 70px;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;
const StyledInfoBackground = styled.div`
background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0AC18E;
  border-radius: 50px 50px 50px 50px;
  width: 100%;
  height: 75px;
  position: absolute;
  top: -3px;
  font-size: 16px;
  font-weight: 600;
  flex-wrap: wrap;
  margin-bottom: 10px;
  background-color: #000;
  opacity: 0.5;
  z-index: -1;
`;
const StyledGroup = styled.div`
  display: flex;
  flex-direction: column;
  border: 0px solid yellow;
  width: 170px;
`;
const StyledItem = styled.div`
  text-align: center;
`;
const StyledButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  border: 0px solid yellow;
  width: 150px;
  gap: 5px;
  height: 60px;
  position: relative;
  left: 20px;
`;
const StyledButtons = styled.button<StyledButtonsProps>`
  border-right: 1px solid ${props => props.type == 'reset' ? '#fff' : '#fff'};
  border-bottom: 1px solid ${props => props.type == 'reset' ? '#fff' : '#fff'};
  border-radius: 20px 20px 20px 20px;
  border-top: 0px;
  border-left: 0px;
  width: 100%;
  height: 100%;
  background-color: ${props => props.type == 'reset' ? '#bf2626' : '#00845f'};
  box-shadow: 2px 2px 4px white inset;
  color: ${props => props.type == 'reset' ? '#fff' : '#fff'};
  font-weight: 600;
  &:hover {
    background-color: ${props => props.type == 'reset' ? '#e04747' : '#0AC18E'};
    border: 0px solid ${props => props.type == 'reset' ? '#960000' : '#005f45'};
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff};
  }
`;
const StyledChangesContainer = styled.div`
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  border: 0px solid #0AC18E;
  width: 260px;
  height: 150px;
`;
const StyledChanges = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  border: 0px solid red;
  width: 97%;
  height: 100%;
  padding-left: 5px;
  word-break: break-word;
  font-size: 14px;
  font-weight: 700;
`;
const StyledToolsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  border: 0px solid yellow;
  align-items: center; 
  justify-content: center; 
  width: 40px;
  z-index: 9;
  position: relative;
  left: -10px;
`;
const StyledColorPicker = styled.input`
  width: 30px;
  height: 30px;
  border: 0px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0AC18E;
  }
`;
const StyledEraser = styled.button<StyledEraserProps>`
  background-image: url(${ImageEraser});
  background-size: cover;
  width: 30px;
  height: 30px;
  border: ${props => props.active ? '4px' : '2px'} solid ${props => props.active ? 'red' : '#ccc'};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0AC18E;
  }
`;
const StyledTitle = styled.div`
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  border-bottom: 1px solid #0AC18E;
`;
export default Canvas;