import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import BigPic from '../../assets/img/casualLogo.png'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import CashCodes from '../../assets/img/CashCodes.png'
import Startedbar from '../../components/StartedBar/Startedbar';
import CountdownTimer from '../../components/CountDowner/CountDowner';




const GettingStarted:React.FC = () =>{
 
    // Define your box data
    const boxes = [
      { href: 'https://github.com/cashninjas', top: '20%', left: '2%', width: '75%', height: '12%' },
      { href: 'https://tbch.googol.cash/', top: '40%', left: '2%', width: '90%', height: '12%' },
      { href: 'https://github.com/fex-cash/fex', top: '60%', left: '2%', width: '85%', height: '12%' },
      { href: 'https://playground.cashscript.org/', top: '80%', left: '2%', width: '75%', height: '12%' },
      // ... more boxes
    ];

    


  return (
    <StyledGettingStarted>
      <HeaderWrapper>
   
        <ImageContainer>
          <img src={BigPic} alt="Bitcoin Cash Logo" />
        </ImageContainer> 
      </HeaderWrapper>
    {/* Add your content here */}

      <StyledFactCubes id="InfoCubes">
        <FactCube to="/low-fees">
          <h2>Low Fees</h2>
          <p>It cost almost nothing to use, no matter where you send</p>
        </FactCube>
        <FactCube to="/scalable">
          <h2>Scalable</h2>
          <p>Built to scale based off demand and usage.</p>
        </FactCube>
        <FactCube to="/smart-contracts">
          <h2>Smart Contracts</h2>
          <p>Execute smart contracts seamlessly.</p>
        </FactCube>
        <FactCube to="/fast">
          <h2>Fast</h2>
          <p>Transactions processed quickly.</p>
        </FactCube>
        <FactCube to="/reliable">
          <h2>Reliable</h2>
          <p>A network with no downtime.</p>
        </FactCube>
        <FactCube to="/decentrilized">
          <h2>Truely decentrilized</h2>
          <p>No centeral party. Multiple Dev groups.</p>
        </FactCube>
      </StyledFactCubes>


    
      <StyledSection>
      <>
      <SectionA id="CountDowns">
            {/* CountDowners main controlled in their tsx, beyond this div*/}
    <h2>The Count Down Is On!</h2>
    <div>
      <CountdownWrapper>
        <CountdownTimer targetDate="2024-04-15T00:00:00" eventName="Block Halving" />
        <CountdownTimer targetDate="2024-05-13T00:00:00" eventName="Upgrade Day" />
      </CountdownWrapper>
    </div>
      </SectionA>
      
      <SectionB id="Community Showcase">
      <h2>Section B</h2>
        <p>Content for Section B goes here.</p>
      </SectionB>
      
      <SectionC id="CodeInfo">
      <h2></h2>
      <SectionCImageContainer>
          <SectionCStyledImage src={CashCodes} alt="Code Tips" />
          {boxes.map((box, index) => (
            <SectionCClickableArea
              key={index}
              href={box.href}
              target="_blank" // opens link in a new tab
              rel="noopener noreferrer" // for security reasons
              style={{
                top: box.top,
                left: box.left,
                width: box.width,
                height: box.height,
              }}
            />
          ))}
        </SectionCImageContainer>
 
      </SectionC>
      
      <SectionD id="sectionD">
        <h2>Section D</h2>
        <p>Content for Section D goes here.</p>
      </SectionD>
      
      <SectionE id="sectionE">
        <h2>Section E</h2>
        <p>Content for Section E goes here.</p>
      </SectionE>
    </>
       
     </StyledSection>
    </StyledGettingStarted>

  );
};


const StyledGettingStarted = styled.div`
background-color: #222;
display: flex;
flex-direction: column;
align-items: center; /* Center align the content horizontally */
justify-content: center; /* Center align the content vertically */
`;

const HeaderWrapper = styled.div`
position: relative;
width: 100%;
background-color: black; /* Set the background color for the header */
text-align: center; /* Center align the header content horizontally */
padding: 20px; /* Adjust padding as needed */
display: flex;
justify-content: center; /* Center align content horizontally */
align-items: center; /* Center align content vertically */
max-height: 350px; /* Set the maximum height for the header */
overflow: hidden;
`;

const ImageContainer = styled.div`
  /* Style your image container as needed */
  background-color: black;

  width: 80%; /* Adjust the maximum width of the image container */
  img {
    max-width: 100%; /* Allow the image to shrink to fit the container */
    max-height: 100%; /* Ensure the image fits within the container's max height */
    height: auto; /* Maintain aspect ratio while scaling */
    display: block; /* Remove any default inline styling on the image */
    margin: 0 auto; /* Center align the image horizontally */
  }
`;

const StyledFactCubes = styled.div`
background-color: #0AC18E;
padding: 1px;
color: white;
display: flex;
flex-grow: 1;
flex-wrap: wrap;
flex-direction: row;
justify-content: flex-start;
align-items: center;
margin-bottom: 3px;
cursor: pointer;

`;

const FactCube = styled(Link)`
  background-color: #0AC18E; /* Set the background color for the cubes */
  padding: 10px; /* Adjust padding as needed */
  text-align: center;
  text-decoration: none;
  color: #333; /* Set text color */
  transition: background-color 0.3s ease-in-out;
  height: 80px;
  width: 200px;
  &:hover {
    background-color: #ffffff;

  }
  h2 {
    margin: 0;
  }

  p {
    font-size: 14px;
    margin-top: 8px;
  }
`;




const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
`;


const Section = styled.div`
  width: 100vw; // 100% of the viewport width
  height: 100vh; // 100% of the viewport height
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const SectionA = styled(Section)`
  background-image: linear-gradient(to bottom right, #5C85AD, #729FBF); // Blue to Lighter Blue
`;

const SectionB = styled(Section)`
  background-image: linear-gradient(to bottom right, #368B65, #6DFF8D); // Light Lime to Green Yellow 
`;

const SectionC = styled(Section)`
  background-image: linear-gradient(to bottom right, #20B2AA, #008B8B); // Light Sea Green to Dark Cyan
`;

const SectionD = styled(Section)`
  background-image: linear-gradient(to bottom right, #778899, #2F4F4F); // Light Slate Gray to Dark Slate Gray
`;

const SectionE = styled(Section)`
  background-image: linear-gradient(to bottom right, #9370DB, #4B0082); // Medium Purple to Indigo
`;
const CountdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    
  }
`;
const SectionCImageContainer = styled.div`
  position: relative;
  width: 50%/* width of your image */;
  height: /* height of your image */;
`;

const SectionCClickableArea = styled.a`
  position: absolute;
  border: 1px solid green;
  width: 10px; /* width of clickable area */
  height: /* height of clickable area */;
  top: /* distance from the top edge of the ImageContainer */;
  left: /* distance from the left edge of the ImageContainer */;
  cursor: pointer;
  &:hover {
    /* styles for hover effect if needed */
  }
`;

const SectionCStyledImage = styled.img`
  display: block; // This ensures there is no space beneath the image
  width: 100%;
  height: auto;
`;
export default GettingStarted;