const socialsAds = [
    { 
      name: 'BCHChannel',
      website: 't.me/bchchannel',
      logo: 'bchchannel.jpg',
      description: 'General BCH channel chatroom',
      socialHandles: [],
      videos: [],
      longerDescription: 'telegram.png',
      tags:[],
    },
    { 
      name: 'BCHChannel',
      website: 't.me/bchpricechannel',
      logo: 'bchprice.png',
      description: 'General BCH price chat',
      socialHandles: [],
      videos: [],
      longerDescription: 'telegram.png',
      tags:[],
    },


    { 
      name: 'Shrimp Club',
      website: 't.me/shrimp_club_bch',
      logo: 'shrimp.png',
      description: '',
      socialHandles: [],
      videos: [],
      longerDescription: 'telegram.png',
      tags:[],
    },
    { 
      name: 'Philippines BCH',
      website: 't.me/bchpilipinas',
      logo: 'philippinesbch.png',
      description: '',
      socialHandles: [],
      videos: [],
      longerDescription: 'telegram.png',
      tags:[],
    },
    { 
      name: 'BCH News',
      website: 't.me/BitcoinCashUpdates',
      logo: 'bchnews.png',
      description: 'General BCH news',
      socialHandles: [],
      videos: [],
      longerDescription: 'telegram.png',
      tags:[],
    },
    { 
      name: 'BCH Meme Creators',
      website: 't.me/BCHMEMES',
      logo: 'seziememes.jpg',
      description: 'Memes Memes Moar Memes!',
      socialHandles: [],
      videos: [],
      longerDescription: 'telegram.png',
      tags:[],
    },
    { 
      name: 'Israeli Bitcoiners',
      website: 't.me/israelibitcoiners',
      logo: 'israeli.png',
      description: 'local news and adoption reports',
      socialHandles: [],
      videos: [],
      longerDescription: 'telegram.png',
      tags:[],
    },

    { 
      name: 'Merchant Adoption',
      website: 't.me/merchantadoption',
      logo: 'bch.png',
      description: '',
      socialHandles: [],
      videos: [],
      longerDescription: 'telegram.png',
      tags:[],
    },
    { 
      name: 'r/BTC',
      website: 'reddit.com/r/btc/',
      logo: 'bch.png',
      description: 'A place to discuss all things Bitcoin',
      socialHandles: [],
      videos: [],
      longerDescription: 'reddit.png',
      tags:[],
    },
    { 
      name: 'r/BitcoinCash',
      website: 'reddit.com/r/Bitcoincash/',
      logo: 'bch.png',
      description: 'All Bitcoiners are welcome to join the Bitcoin Cash.',
      socialHandles: [],
      videos: [],
      longerDescription: 'reddit.png',
      tags:[],
    },
    { 
      name: 'r/CashTokens',
      website: 'reddit.com/r/cashtokens/',
      logo: 'Ctoken.png',
      description: 'A place to discuss all things CashTokens.',
      socialHandles: [],
      videos: [],
      longerDescription: 'reddit.png',
      tags:[],
    },
    { 
      name: 'r/BCHCashTokens',
      website: 'reddit.com/r/BCHCashTokens/',
      logo: 'Ctoken.png',
      description: 'BCH & CashTokens Free Discussion.',
      socialHandles: [],
      videos: [],
      longerDescription: 'reddit.png',
      tags:[],
    },
    { 
      name: 'Bitcoin Cashers',
      website: 'discord.gg/w5vQYsrBZy',
      logo: 'bch.png',
      description: 'Join the Bitcoin Cashers community on Discord.',
      socialHandles: [],
      videos: [],
      longerDescription: 'discord.png',
      tags:[],
    },
    { 
      name: 'BCH Message Board',
      website: 'coinmarketcap.com/community/profile/bitcoincash/',
      logo: 'bchchannel.jpg',
      description: 'Join  CoinMarketCap`s message boards today!',
      socialHandles: [],
      videos: [],
      longerDescription: 'coinmarketcap.png',
      tags:[],
    },

    { 
      name: '/rCashTokens ',
      website:'t.me/rcashtokens',
      logo: 'Ctoken.png',
      description: 'Friendly Cashtokens telegram!',
      socialHandles: [],
      videos: [],
      longerDescription: 'telegram.png',
      tags:[],
    },
    { 
      name: 'SLP Devs For Hire',
      website: 't.me/slp_devs_for_hire',
      logo: 'slp.png',
      description: '',
      socialHandles: [],
      videos: [],
      longerDescription: 'telegram.png',
      tags:[],
    },
    { 
      name: 'Native Wallets Dev',
      website: 't.me/native_bch',
      logo: 'nativetg.png',
      description: 'Discussion for BCH Wallet Development',
      socialHandles: [],
      videos: [],
      longerDescription: 'telegram.png',
      tags:[],
    },
    { 
      name: 'BCH Devs & Builders',
      website: 't.me/bchbuilders',
      logo: 'bchdevsbuilders.jpg',
      description: 'General Developer building & discussion',
      socialHandles: [],
      videos: [],
      longerDescription: 'telegram.png',
      tags:[],
    },
    { 
      name: 'Cashtoken Devs ',
      website:'t.me/cashtoken_devs',
      logo: 'cashtokendev.png',
      description: 'General Cashtoken Developer building.',
      socialHandles: [],
      videos: [],
      longerDescription: 'telegram.png',
      tags:[],
    },
    { 
      name: 'Cashtoken Devs ',
      website:'t.me/bch_compilers',
      logo: 'bch.png',
      description: 'Cashscript development/building.',
      socialHandles: [],
      videos: [],
      longerDescription: 'telegram.png',
      tags:[],
    },

  ]

export default socialsAds