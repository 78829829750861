import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import Sidebar from '../../components/SideBar'; 


interface Category {
  name: string;
  questions: { question: string; answer: string; answerVisible: boolean }[];
}

interface StyledAnswerProps {
  visible: boolean;
}


const categories: Category[] = [
  {
    name: 'BitcoinCash',
    questions: [
      {
        question: 'What Is Bitcoin Cash?',
        answer: 'Bitcoin Cash (BCH) is a digital currency that enables fast, low-cost payments to anyone in the world. It\'s a decentralized peer-to-peer network with no central authority, meaning it operates without being under the control of any group or government.',
        answerVisible: false,
      },
      {
        question: 'When was Bitcoin Cash created?',
        answer: 'Depends who you ask! <p /> Bitcoin Cash (BCH) emerged in August 2017 due to the Bitcoin community disagreeing about how Bitcoin should work. On one side the "small blockers" wanted Bitcoin to restrict the number of transactions and rely on high fees to stay sustainable. \n\
        That is BTC today. <p /> On the other side the "big blockers" wanted Bitcoin to keep growing through increased user adoption, staying sustainable with large numbers of low-fee transactions. That is BCH today. <p /> The years of disagreement over this is called the \'Blocksize Wars\'. \n\
        Bitcoin Cash emerged in August 2017 just before a major technical change (Segregated Witness) was implemented which most big-blockers disagreed with. There are many people who chose to support Bitcoin Cash who have been in Bitcoin since 2009 and onwards, so for them \n\
        BCH is the Bitcoin they have always used.',
        answerVisible: false,
      },
      {
        question: 'What upgrades has BCH done since 2017?',
        answer: 'BCH increased the blocksize limit, allowing for more transactions to be included in each block so that high fees would not occur. Initially up to 8MB, then up to 32MB. <p /> \n\
        BCH has diverged further from BTC by adding features like: \n\
        <ul><li><a href="https://documentation.cash/protocol/forks/2020-11-15-asert" target="_blank">ASERT</a>, a more resilient dynamic difficulty algorithm.</li> \n\
        <li><a href="https://cashtokens.org/">CashTokens</a>, native tokens and smart contracts that are miner-validated and scalable, only possible with numerous other technical changes that were made.</li>\n\
        <li>upcoming in 2024: <a href="https://gitlab.com/0353F40E/ebaa">ABLA</a>, an Adaptive Blocksize Limit Algorithm which will allow the blocksize to grow (and shrink) automatically if enough usage occurs, taking control of the blocksize out of developers hands.</li></ul> \n\
        <li>And many others</li>',
        answerVisible: false,
      },
      {
        question: 'Are there transaction fees in Bitcoin Cash?',
        answer: 'Yes, but they are typically very low, often only a fraction of a cent. See the current fees at <a href="https://bitcoinfees.cash/">bitcoinfees.cash</a>. This makes it feasible for small and micro-transactions, as well as making the network usable by anyone in the world, regardless of their personal or regional financial situation. \n\
        Having these transaction fees, even though quite low, provide benefits to the network: \n\
        <ul><li>Anti-spam: Maliciously filling up the networks capacity by spamming transactions would cost the attacker money. This becomes even more expensive for the attacker when considering BCH\'s scaling blocksize.</li> \n\
        <li>Miner income: Miners are the reason the network works in the first place. Without miners, there is no network. Miners need to get paid to perform this service. BCH follows the original Bitcoin design: a \n\
        large number of small-fee transactions will make the miners income sustainable. <a href="https://read.cash/@SayoshiNakamario/btc-vs-bch-economics-95e22084">See here for further details.</a>',
        answerVisible: false,
      },
      {
        question: 'What is a wallet?',
        answer: 'A wallet is something that stores your private keys. Your private keys are what control your coins - think of them like a password that lets you control your money. <p />If someone knows your password they can control your money, so keep your private keys safe! <p />\n\
        Wallets come in many forms, often as applications on your phone or computer, but you can also have wallets stored on paper as a list of words or a QR code. Some people memorize their list of words (called a "brain wallet") and that is the only place it exists! I hope they wear a helmet...',
        answerVisible: false,
      },
      {
        question: 'How do I get a wallet?',
        answer: 'The simplest way is to get a software wallet like a phone app. It depends what type of wallet you want: \n\
        <ul><li>Phone app: <a href="https://www.paytaca.com/">Paytaca</a>, <a href="https://zapit.io/">Zapit</a>, <a href="https://selene.cash">Selene</a> (many others exist)</li> \n\
        <li>Computer app: <a href="https://electroncash.org">Electron Cash</a></li>\n\
        <li>Paper Wallet: Generate seed words from an offline app (e.g. a phone or computer app from a device that never goes on the internet), or create the private key manually by rolling dice or flipping a coin (yes, really!) \n\
        <li>Hardware Wallet: A device you can buy that stores private keys and can generate new ones. They are typically connected to a computer or phone so apps can request the hardware wallets permission to spend its \n\
        coins - which you have to approve - but the private keys themselves (should) never leave the hardware wallet.',
        answerVisible: false,
      },
      {
        question: 'How does Bitcoin Cash handle privacy?',
        answer: 'Transactions on the Bitcoin Cash network are public, but the identities of the people involved in transactions are pseudonymous. This means they are not directly tied to real-world identities. There are companies that \n\
        try to do this, like Chainalysis. There are also optional privacy features on Bitcoin Cash to counteract those efforts, like CashFusion.',
        answerVisible: false,
      },
      {
        question: 'What is CashFusion?',
        answer: 'CashFusion is an optional privacy protocol on Bitcoin Cash. Another term for it is coin mixer. Essentially people enable CashFusion on their wallet, and periodically everyones wallet running CashFusion will throw all their coins into the \n\
        same bucket and mixes them up. Then everyone takes out random coins of the same amount they started with. This keeps repeating automatically while CashFusion is enabled. This results in gigantic headaches for anyone trying to track ownership of coins. \n\
        CashFusion is doing transactions to accomplish this, so there is a small fee involved to perform it.',
        answerVisible: false,
      },
      {
        question: 'Where can I find more information about BCH?',
        answer: 'There\'s this great site called <a href="https://helpme.cash">helpme.cash</a> that might help. 😄\n\
        <p />\n\
        Jeremy from the BitcoinCashPodcast has also created a <a href="https://bitcoincashpodcast.com/faqs">large FAQ</a>.',
        answerVisible: false,
      },
      {
        question: 'What can I do to support Bitcoin Cash?',
        answer: 'Anything and everything! <p />Talk about it, develop for it, create music and memes, try to break it! But most of all - use it! \n\
        If you want something more specific you can contact the <a href="https://bitcoincashfoundation.org/">Bitcoin Cash Foundation</a> who might be able to point you in the right direction.',
        answerVisible: false,
      },
      {
        question: 'What is the Lightning Network?',
        answer: 'The Lightning Network (LN) was an attempt to build a \'second layer\' payment network on top of Bitcoin BTC. Its purpose was to allow users to open a \'payment channel\' from Bitcoin to LN with a single Bitcoin \n\
        transaction. Then the user would be able to perform many low-fee transactions on LN. Unfortunately LN has multiple fundamental issues with its design that have remain unsolved, and are likely to remain that way, \n\
        which many users from the Bitcoin big-block community (BCH) pointed out from as early as 2015. \n\
        <p />\n\
        It\'s also important to note that payment channels are not unique to LN. They are setup between two users, similar to ordering food and drinks at a restaurant. You increase your bill over time but don\'t settle the payment \n\
        until your meal is finished. Payment channels can also be setup on BCH. The difference with LN is that it allows you to use <i>other peoples</i> channels for your own purposes. This could also be built on BCH, but due to the \n\
        fundamental issues and LN being outperformed by the main BCH network, nobody has wanted to do so.',
        answerVisible: false,
      },
      {
        question: 'Why is low-fee/high-use better than high-fee/low-use?',
        answer: 'Bitcoin, the invention, requires miners to use electricity in large amounts to keep the network secure. If they stop doing this then the network will effectively die, making the token worthless. \n\
        You can see a full comparison of the two designs here: <a href="  https://read.cash/@SayoshiNakamario/btc-vs-bch-economics-95e22084">https://read.cash/@SayoshiNakamario/btc-vs-bch-economics-95e22084</a>',
        answerVisible: false,
      },
      {
        question: 'Do Bitcoin miners waste massive amounts of electricy?',
        answer: 'Miners do use large amounts of electricity, there is no way around this fact. However, there is nuance to the situation. <p/> First, Bitcoin Cash is attempting to fully replace the fiat \n\
        banking system. The banking system itself also uses electricity today: bank branches, datacenters, networks, ATM\'s, etc. So you can\'t consider the mining energy usage as a fully new energy cost without comparing it to \n\
        the current systems usage. <p/>Second, the fact miners need to consume real resources, pay a cost, adds to the incentives for miners to keep the network secure and not attempt to attack it, otherwise they can be punished by \n\
        other honest miners and the malicious miners end up paying the mining cost with no reimbursement for it. This is part of what makes the network, an unorganized group competitors driven by greed, work together cooperatively and eliminates the need for trusted 3rd-parties, like banks. <p/> Third, the BCH network can support very large numbers of users and activity, but the mining network doesn\'t need to scale up \n\
        the same way to continue supporting them. For example, if a million users are using and transacting on BCH the mining network will be some specific size, running the network. If suddenly the number of users and transactions increases to \n\
        100 million users, the mining network doesn\'t need to increase in size at all to still support all the new users. It\'s likely that it <i>will</i> increase in some amount, as the value of the BCH coin will increase from all the new user \n\
        demand, but it doesn\'t mean the mining network will suddenly increase. <p/>This is also why as the Bitcoin Cash network (users) grows larger, the efficiency of the network in terms of power used per user is also likely to get better over time. \n\
        It\'s entirely possible Bitcoin Cash becomes a much more power-efficient payments network than the existing fiat banking system. (What those numbers are I have no idea yet!)',
        answerVisible: false,
      },
    ],
  },
  {
    name: 'CashTokens',
    questions: [
      {
        question: 'What is CashTokens?',
        answer: 'Miner-validated fungible and non-fungible tokens on Bitcoin Cash, activated in the May 2023 upgrade. <a href="https://cashtokens.org/">Specification</a> \n\
        <p />\n\
        These differ significantly from other UTXO chains, like BTC, in the miner-validated part. This means tokens on BCH are a part of the transaction itself which miners verify, \n\
        the same way tokens are verified on EVM systems like Ethereum.\n\
        <p />\n\
        This is a notable difference because past techniques to create UTXO tokens, like BTC\'s Ordinals, do it by placing the token data in an area that miners do not verify.\n\
        This requires setting up and relying upon indexing servers. BCH also experimented with these in the form of SLP (Simple Ledger Protocol) which was released in 2018 and is still active today, but it ran into scaling and usability issues due to the inherent issues that index \n\
        servers and unverified tokens have.\n\
        <p />\n\
        Besides tokens, the Bitcoin scripting capabilities have been expanded over time on BCH. With the new tooling beginning to come out in 2023 we are just getting to the state of developers being able to easily make complex, Ethereum-like smart contracts directly on BCH. \n\
        And they scale.😊',
        answerVisible: false,
      },
      {
        question: 'How do transaction fees compare between CashTokens and Ethereum?',
        answer: 'CashTokens benefit from the low transaction fees of the Bitcoin Cash network. There is very little increase in BCH\'s sub-cent fees for smart contract transactions. Ethereum-style network transaction fees can vary greatly and have been known to spike during high demand due to their "gas" costs, a limit related to their use of on-chain state and processing.',
        answerVisible: false,
      },
      {
        question: 'What is CashScript?',
        answer: 'CashScript is a high-level programming language specifically designed for writing smart contracts on the Bitcoin Cash blockchain. It is similar to Ethereum\'s Solidity but is tailored to the unique features and capabilities of Bitcoin Cash. <a href="https://cashscript.org/">CashScript.org</a>',
        answerVisible: false,
      },
      {
        question: 'What are BCMR and CRC20?',
        answer: 'They are both token standards that are built with the CashTokens specification, but are independant implementations. \n\
        <p/>CRC20 tokens are more similar to the Ordinal-style coins on other chains, like BTC. One feature of them is that CRC20 tokens cannot have duplicate ticker symbols, so if someone creates a token with the ticker \'HELPME\' then nobody else can use that ticker name. \n\
        This was criticized by some in the community as it lead to many ticker symbols being \'squatted\' by speculators. The <a href="https://fex.cash/">Fex.cash DEX</a> is focused on CRC20 tokens. \n\
        <p\>BCMR tokens don\'t enforce unique ticker symbols, but also include the <a href="https://cashtokens.org/docs/bcmr/chip/">BCMR metadata standard</a>. This standard allows token creators to securely link their token to a file full of whatever data they want,\n\
        and the file can be stored anywhere. This metadata can include basic data like the tokens ticker symbol and number of decimal places, to fully custom data like NFT traits, video game item stats, etc. The token creator also has the option to keep the ability to update\n\
        the contents of the file in the future.\n\
        <p\>Both token standards are using the CashTokens specification, but some services may be focused more around one type of standard.',
        answerVisible: false,
      },
      {
        question: 'What can you do with CashTokens?',
        answer: 'The name CashTokens makes you think it\'s just for creating tokens and NFT\'s, but it enables much more than just that. You can create smart contracts giving rules to coins that must be followed for a transaction to be allowed. \n\
        This enables the creation of everything from games utilizing NFT\'s to decentralized exchanges (DEX\'s). If you can imagine a use-case, there is probably a way to create it with CashTokens. \n\
        <p/>Current example apps:<p/> \n\
          <ul><li><a href="https://tapswap.cash/">TapSwap.cash (NFT Marketplace)</a></li>\n\
          <li><a href="https://cauldron.quest/">Cauldron.quest (DEX)</a></li>\n\
          <li><a href="https://fex.cash/">Fex.cash (DEX)</a></li>',
        answerVisible: false,
      },
      {
        question: 'How can I get started with CashTokens?',
        answer: '<ul><li>An in-depth tutorial series: <a href="https://www.bitcoincashsite.com/blog">Token Pioneers by George Donnelly</a></li>\n\
        <li>GUI CashTokens interfance: <a href="https://chipnet.cashtokens.studio/">CashTokens Studio</a> (on Chipnet by default)</li>\n\
        <li>CashToken <a href="https://www.youtube.com/@mrzwets/videos">Video Tutorials</a> by Mathieu Geukens</li>\n\
        <li>BCH <a href="https://explorer.salemkode.com/">Block Explorer</a> with CashToken support by Salem Kode</li>',
        answerVisible: false,
      },
    ],
  },
  {
    name: 'HelpMe',
    questions: [
      {
        question: 'What is HelpMe?',
        answer: 'An information link directory for the Bitcoin Cash (BCH) ecoystem. It\'s a passion project by husband and wife duo Sayoshi Nakamario and VinRadiant.',
        answerVisible: false,
      },
      {
        question: 'How do I get something listed or updated?',
        answer: 'Drop us a message or use the Submit button at the bottom of the page.',
        answerVisible: false,
      },
      {
        question: 'Is everything on HelpMe safe?',
        answer: 'We don\'t personally vet everything, so we can\'t guarantee it. As with all things in crypto, do your own research such as verifying with others in the community.',
        answerVisible: false,
      },
      {
        question: 'Do you make any money from running HelpMe?',
        answer: 'Nope! Donations are welcome and are reserved for running costs and upgrades.',
        answerVisible: false,
      },
    ],
  },
  // Add more categories...
];

const FAQ = () => {
  const [selectedCategory, setSelectedCategory] = useState<null | Category>(null);

  const handleCategoryClick = (category: Category) => {
    setSelectedCategory(category);
  };

  const toggleQuestion = (index: number) => {
    setSelectedCategory((prevCategory) => {
      if (!prevCategory) return prevCategory; 
  
      const newCategory: Category = {
        ...prevCategory, 
        questions: prevCategory.questions.map((q, idx) => {
          if (idx === index) {
            return { ...q, answerVisible: !q.answerVisible };
          }
          return q;
        }),
      };

      return newCategory;
    });
  };

  return (
    <FAQContainer>
 
      <FAQContent>
        <Sidebar categories={categories} onCategoryClick={handleCategoryClick} />
        {selectedCategory ? (
          <StyledUL>
               {selectedCategory.questions.map((faq, index) => (
              <li key={index}>
                <StyledTitle onClick={() => toggleQuestion(index)}>
                  {faq.question}
                </StyledTitle> 
                <StyledAnswer visible={faq.answerVisible}>
                  <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                </StyledAnswer>
              </li>
            ))}
          </StyledUL>
        ) : (
          <p>Welcome to the FAQ page. <br />
          Select a category to view commonly asked questions. <br />
          Hopefully you find what you are looking for!</p>
        )}
      </FAQContent>
    </FAQContainer>
  );
};

const StyledUL = styled.button`
  background-color: transparent;
  border: 0px;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  left: -50px;
`;
const StyledTitle = styled.button`
  background-color: black;
  color: white;
  font-size: 16px;
  border: 1px solid #0AC18E;
  border-radius: 5px; 
  padding: 5px 10px;  
  cursor: pointer;
  text-align: left;    
  width: 800px;     
  min-width: 400px;
  margin-bottom: 5px;  // Some space between title and answer
  &:hover {
    background-color: #0AC18E; /* Background color on hover */
  }
`;
const StyledAnswer = styled.div<StyledAnswerProps>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  border: 1px solid #0AC18E;
  background-color: #333;
  color: white;
  font-size: 14px;
  margin-bottom: 5px; 
  border-radius: 5px;
  position: relative;
  left: 20px;
  max-width: 750px;
  word-wrap: break-word;
  text-align: left;
  padding-left: 10px;
  line-height: 18px;
  a {
    color: #09b182;
    &:hover {
      color: #0ad69e;
    }
  }
`;
const FAQContainer = styled.div`
background-color: #222;
height: 100%;
min-height: 500px;
color: white;
text-align: center;
`;
const FAQContent = styled.div`
display: flex;
margin-left: 300px; /* Adjust this value to match the width of the sidebar */
padding: 20px;
background-color: #222;
`;

export default FAQ;
