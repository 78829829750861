import React, { useState } from 'react';
import styled from 'styled-components';
import BigPic from '../../assets/img/casualLogo.png'


function Submissions() {
    const [formData, setFormData] = useState({
      name: '',
      website:'',
      logo: '',
      description: '',
      socialHandles: [''],
      videos: '',
      longerDescription: '',
      contactInfo: '',
    });
  //////////////////////////////////////////  
/////  Handle submitting a new event
//////////////////////////////////////////
const handleSubmit = async () => {
    console.log(formData);
    const response = await fetch('https://helpme.cash/api/project', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert("Received! Thanks for the submission.")
      console.log('Server received submission');
    }
  };
   
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    return (
      <Container>
         <BackgroundDiv />
       <StyledWrapper>
         <Heading>Submission Form</Heading>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Name:</Label>
            <Input
              type="text"
              name="name"
              placeholder="HelpMe"
              value={formData.name}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Website:</Label>
            <Input
              type="text"
              name="website"
              placeholder="helpme.cash"
              value={formData.website}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Logo (link):</Label>
            <Input
              type="text"
              name="logo"
              placeholder="https://helpme.cash/gallery/helpmelogo-ts1641361464.png"
              value={formData.logo}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Description (max 140characters):</Label>
            <Input
              type="text"
              name="description"
              placeholder="This is a useful site that has lots of great community links!"
              value={formData.description}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Social Handles:</Label>
            <Input
              type="text"
              name="socialHandles"
              placeholder="t.me/helpmedotcash,twitter.com/VinRadiant,DiscordIfWeHadOne"
              value={formData.socialHandles}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Embeded Youtube Video:</Label>
            <Input
              type="text"
              name="videos"
              placeholder="https://www.youtube.com/embed/dQw4w9WgXcQ"
              value={formData.videos}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Longer Description:</Label>
            <Input
              type="text"
              name="longerDescription"
              placeholder="This is for the openable modal, and can be long!"
              value={formData.longerDescription}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Who should I contact if I need more information?</Label>
            <Input
              type="text"
              name="contactInfo"
              placeholder="Optional: Your Telegram,Discord,Twitter. If errors"
              value={formData.contactInfo}
              onChange={handleChange}
            />
          </FormGroup>
          <SubmitButton type="button" onClick={handleSubmit}>Submit</SubmitButton>

        </Form>
        </StyledWrapper>
      </Container>
    );
  }
  

  const BackgroundDiv = styled.div`
  background-image: url(${BigPic});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  display: flex;
  height: 100%;
  max-height: 816px;
  width: 100%;
  border: 0px solid red;
  z-index: -1;
  opacity: 0.4;
`;

  const Container = styled.div`
  font-family: Arial, sans-serif;

  margin: 0 auto;
  
  display: flex;
  width: 100%;
position: relative;

`;

const StyledWrapper = styled.div`
display: flex;
align-items: center;
flex-direction: column;

width: 100%;
position: relative;

`;


const Heading = styled.h1`
  text-align: center;
  color: white;

  text: bold;
  font-size: 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-weight: bold;
  color: white;
  font-size: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  background-color: white;
  border: 2px solid green;
`;

const SubmitButton = styled.button`
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: 2px solid #4caf50;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 20px;
  &:hover {
    background-color: #0ac18e; /* Green background color on hover */
    box-shadow: 0 0 10px rgba(0, 128, 0, 0.5); /* Glow effect on hover */
  }
  
`;

export default Submissions;
