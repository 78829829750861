

/* { 
  name: '',
  website: '',
  logo: '',
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
  tags:['CashTokens'],
}, */


const cashTokensAds =[
      { 
        name: 'Tapswap',
        website: 'tapswap.cash',
        logo: 'tapswap.png',
        description: 'Cashtokens Marketplace',
        socialHandles: ['t.me/TapSwapCash','twitter.com/mainnet_pat'],
        videos: [],
        longerDescription: '',
        tags:['CashTokens'],
      },
      { 
        name: 'Cauldron',
        website: 'cauldron.quest',
        logo: 'cauldron.png',
        description: 'Cauldron DEX powered by Bitcoin Cash built using Cash Tokens.',
        socialHandles: ['discord.com/invite/sXzdKq4eQU','twitter.com/CauldronSwap'],
        videos: [],
        longerDescription: 'Cauldron employs a novel micro-pools approach, allowing individual users to operate small-scale CPMM contracts. This design, taking advantage of Bitcoin Cashs UTXO model and capability to hold tokens and BCH in the same UTXO, aims for scalability and efficiency, fostering the growth of the token ecosystem on Bitcoin Cash',
        tags:['CashTokens'],
      },
      { 
        name: 'Fex',
        website: 'fex.cash/bulk',
        logo: 'fex.png',
        description: 'decentralized exchange (DEX) that allows users to trade CRC20 tokens and BCH.',
        socialHandles: ['twitter.com/FexCash','t.me/fexcash_disscusion'],
        videos: [],
        longerDescription: ' non-custodial exchange, which means that users retain full control of their assets at all times. The trading process is managed by smart contracts, which are self-executing contracts that are stored on the blockchain. This ensures that all transactions are transparent and secure. ',
        tags:['CashTokens'],
      },
      { 
        name: 'BCMR Generator',
        website: 'bcmr-generator.netlify.app',
        logo: 'nologo.png',
        description: 'Generate the BCMR Json file for your CashTokens project!',
        socialHandles: ['github.com/mr-zwets/bcmr-generator'],
        videos: [],
        longerDescription: '',
        tags:['CashTokens'],
      },
      { 
        name: 'Hashes For Cashers',
        website: 'hashes-ashen.vercel.app',
        logo: 'nologo.png',
        description: 'Use this site to learn about the power and utility of Bitcoin Cash Tokens and Smart Contracts',
        socialHandles: [],
        videos: [],
        longerDescription: 'A simple guessing game. Or learn about contracts and help yourself to the bounty! Use this site to learn about the power and utility of Bitcoin Cash Tokens and Smart Contracts. For Educational Purposes only.',
        tags:['CashTokens'],
      },
      { 
        name: 'CashTokens Studio',
        website: 'chipnet.cashtokens.studio',
        logo: 'cashstudio.png',
        description: '[Chipnet test mode] Create and manage tokens and NFTs on CashTokens, by Paytaca',
        socialHandles: ['t.me/CashTokensStudio'],
        videos: [],
        longerDescription: '',
        tags:['CashTokens'],
      },
      { 
        name: 'CRC20 Token Explorer',
        website: 'crc-token.vercel.app',
        logo: 'crc20explorer.png',
        description: 'A site that list CRC20 tokens',
        socialHandles: [],
        videos: [],
        longerDescription: '',
        tags:['CashTokens'],
      },
      { 
        name: 'Tokenstork',
        website: 'tokenstork.com',
        logo: 'tokenstork.png',
        description: 'A site that list tokens built on Cashtokens. ',
        socialHandles: ['twitter.com/bitcoincashsite','t.me/Panmoni/487'],
        videos: [],
        longerDescription: 'TokenStork "Delivering beautiful new CashTokens on BCH since 2023" is a Panmoni project ',
        tags:['CashTokens'],
      },
      { 
        name: 'Bitcoin Cash Site Tutorials',
        website: 'bitcoincashsite.com/blog',
        logo: 'bitcoincashsite.png',
        description: 'CashToken development tutorials by George Donnelly.',
        socialHandles: ['t.me/bitcoincashsite'],
        videos: [],
        longerDescription: '',
        tags:['CashTokens'],
      },
      { 
        name: 'Open Token Registry',
        website: 'otr.cash',
        logo: 'otr.png',
        description: 'OpenTokenRegistry is used to display human-readable names, icons, ticker symbols, and other token information in many BCH wallets and software',
        socialHandles: ['twitter.com/OpenTokenRgstry'],
        videos: [],
        longerDescription: 'The registry is volunteer-maintained by active members of the Bitcoin Cash community. Its free to use in your software, and there are no fees to list new tokens or update token information.',
        tags:['CashTokens'],
      },
      { 
        name: 'Emerald DAO',
        website: 'emerald-dao.cash',
        logo: 'emeralddao.png',
        description: 'Emerald DAO is a simple Bitcoin Cash DAO template which acts as a fixed-term deposit savings vault with optional crowdfunded rewards scheme.',
        socialHandles: ['t.me/emeralddao','twitter.com/EmeraldDaoNFT'],
        videos: [],
        longerDescription: 'The DAO template consists of 3 contracts labeled "blueprint", "vault", and "safebox". The "blueprint" contract will be placed on a pure index-0 BCH UTXO which will be consumed to create the genesis transaction. The genesis transaction will create exactly one NFT with "minting" capability and lock it with the "vault" contract, which is a recursive covenant. Users will interact with the "vault" contract to create pairs of immutable NFTs.',
        tags:['CashTokens'],
      },
      { 
        name: 'Cashonize',
        website: 'cashonize.com',
        logo: 'cashonize.png',
        description: 'BCH web wallet that shows tokens. Create Fungible tokens and NFTs. Made by Mathieu Geukens.',
        socialHandles: ['github.com/cashonize/wallet'],
        videos: [],
        longerDescription: '',
        tags:['CashTokens'],
      },
      { 
        name: 'NFT Viewer',
        website: 'viewer.sploit.cash/',
        logo: 'nftviewer.png',
        description: 'Browse CashTokens NFT Collections. ',
        socialHandles: ['github.com/acidsploit/cashtokens-nft-viewer'],
        videos: [],
        longerDescription: 'By searching for a Bitcoin Cash address you can browse, track and share NFT CashTokens collections.',
        tags:['CashTokens'],
      },
      { 
        name: 'CashScript Tutorials',
        website: 'www.youtube.com/@mrzwets',
        logo: 'mrzwets.png',
        description: 'Cashscript tutorials by Bitcoin Cash developer MrZwets. ',
        socialHandles: ['youtube.com/@mrzwets'],
        videos: ['https://www.youtube.com/embed/LT0veuGbYTQ'],
        longerDescription: '',
        tags:['CashTokens'],
      },
      { 
        name: 'Shinobi Art Engine',
        website: 'github.com/cashninjas/shinobi-art-engine',
        logo: 'shinobi.png',
        description: 'Shinobi Art Engine is a tool used to create BCH NFT collections from image layers! ',
        socialHandles: [],
        videos: ['https://www.youtube.com/embed/oiZm8S37lf8'],
        longerDescription: 'This is a fork of the excellent HashLips Art Engine to support BCH Cash Tokens created by the Cash Ninjas team!',
        tags:['CashTokens'],
      },
      { 
        name: 'Tokennaut',
        website: 'tokenaut.cash',
        logo: 'tokennaut.png',
        description: 'A curated list to help Navigate the NFT,Defi and Dapp projects in the CashTokens space.',
        socialHandles: ['twitter.com/GeukensMathieu'],
        videos: [],
        longerDescription: `Containing useful information on a Dapp like: Launch date, Status, Open sourced or closed source. \n
         NFT information like Mint price, how many have been minted and total supply, revenue, and floor price. `,
        tags:['CashTokens'],
      }, 
      { 
        name: 'Badgers.Cash',
        website: 'badgers.cash',
        logo: 'badgerscash.png',
        description: 'A proof-of-BitcoinCash-ownership meme coin. Single BCH-Stake to earn Badger Coin.',
        socialHandles: ['t.me/badgers_cash'],
        videos: [],
        longerDescription: `The only way to create BadgerCoins is by temporarily locking BCH for an amount of time. \n

        No premine, no developer reservation, no investors.\n
        At the end of the lockup you can 'Unlock' which sends your locked BCH and the earned BadgerCoins back to the same address that initially created the lock.`,
        tags:['CashTokens'],
      },
      { 
        name: 'TokenBurner',
        website: 'www.tokenburner.cash/',
        logo: 'tokenburner.png',
        description: 'Provably burn fungable cashtokens on the Bitcoin Cash blockchain with the burner contract.',
        socialHandles: ['gitlab.com/dagurval/contract-burner'],
        videos: [],
        longerDescription: '',
        tags:['CashTokens'],
      },
      { 
        name: 'FundMe',
        website: 'fundme.cash',
        logo: 'fundme.png',
        description: '[Alpha testing]A decentralized way to crowdfund BCH for your project, similar to Kickstarter or Flipstarter ',
        socialHandles: ['t.me/FundMe_Cash'],
        videos: [],
        longerDescription: '',
        tags:['CashTokens'],
      },
    ]

export default cashTokensAds
