import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import odysee from '../../assets/logos/odysee.png'
import ImageClose from '../../assets/img/close.png';
import ImageCloseHighlight from '../../assets/img/closeHighlight.png';
import defaultImage from '../../assets/logos/default.png';



interface Ad {
  name?: string;
  website?: string;
  logo?: string;
  description?: string;
  socialHandles: string[];
  videos?: string[]; 
  longerDescription?: string; 
}

interface ModalProps {
  onClose: () => void;
  data: Ad | null; // Change 'Ad' to 'Ad | null' to accept null values
}

// Function to map social handles to icons when using Font Awesome
const getSocialIcon = (handle: string) => { // Step 1: font awesome icons search through this list for their icon

  // Check for Font Awesome icons
  if (handle.includes('facebook.com')) return 'facebook';
  if (handle.includes('twitter.com')) return 'twitter';
  if (handle.includes('t.me')) return 'telegram';
  if (handle.includes('discord')) return 'discord';
  if (handle.includes('github.com')) return 'github';
  if (handle.includes('linkedin.com')) return 'linkedin';
  if (handle.includes('tiktok.com')) return 'tiktok';
  if (handle.includes('youtube.com')) return 'youtube';
  if (handle.includes('instagram.com')) return 'instagram';
  if (handle.includes('twitch.tv')) return 'twitch';
  if (handle.includes('patreon.com')) return 'patreon';
  if (handle.includes('reddit.com')) return 'reddit';
  if (handle.includes('medium.com')) return 'medium';
  if (handle.includes('gitlab')) return 'gitlab';
  if (handle.includes('git.xulu')) return 'gitlab';
  if (handle.includes('play.google.com')) return 'google-play';
  if (handle.includes('docker.com')) return 'docker';
  if (handle.includes('slack.com')) return 'slack';
  if (handle.includes('wiki')) return 'wikipedia-w';
  if (handle.includes('spotify')) return 'spotify';

  //custom
  if (handle.includes('noise.app')) return 0;
  if (handle.includes('odysee.com')) return 0;
  if (handle.includes('cointr.ee')) return 0;
  if (handle.includes('cashrain.com')) return 0;
  if (handle.includes('linktr.ee')) return 0;
  if (handle.includes('read.cash')) return 0;
  if (handle.includes('publish0x.com')) return 0;
  // Add more mappings for other social platforms as needed
  return 'external-link-alt'; // Default icon for unknown handles
};

const getCustomIcon = (handle: string) => { // Step 2: custom icons search through this list for their image
  
  if (handle.includes('odysee.com')) return require('../../assets/logos/odysee.png');
  if (handle.includes('cointr.ee')) return require('../../assets/logos/cointree.png');
  if (handle.includes('cashrain.com')) return require('../../assets/logos/cashrain.png');
  if (handle.includes('noise.app')) return require('../../assets/logos/noisecash.png');
  if (handle.includes('linktr.ee')) return require('../../assets/logos/linktree.png');
  if (handle.includes('read.cash')) return require('../../assets/logos/readscash.png');
  if (handle.includes('publish0x.com')) return require('../../assets/logos/publish0x.png');
}

const Modal: React.FC<ModalProps> = ({ onClose, data }) => {
  const [description, setDescription] = useState<React.ReactNode>('');

  const closeModal = () => {
    onClose();
  };

  useEffect(() => {
    if (data && data.longerDescription) {
      const jsxArray = data.longerDescription.split('\n').map((line, index, array) => {
        return index === array.length - 1 ? line : <>
          {line}
          <br />
        </>;
      });
      setDescription(jsxArray);
    } else {
      setDescription('');
    }
  }, [data]);

  if (!data) {
    return null; // Render nothing if data is null
  };

  return (
    <ModalContent onClick={(e) => e.stopPropagation()}>
      <CloseButton onClick={closeModal} />

        {data.logo ? <StyledLogo src={require(`../../assets/logos/${data.logo}`)} alt={data.website} /> : null }
        <StyledWebsite href={`https://${data.website}`} target="_blank" rel="noopener noreferrer">{data.name}</StyledWebsite>
                  <SocialsLinkContainer>
            {data.socialHandles.map((handle: string, handleIndex: number) => (
              <a
                key={handleIndex}
                href={`https://${handle}`}
                target="_blank"
                rel="noopener noreferrer"
              >
              {typeof getSocialIcon(handle) === 'string' ? (
                <i className={`fab fa-${getSocialIcon(handle)} fa-2x`} />
              ) : (
                <StyledIcon src={getCustomIcon(handle)} alt={handle} />
                //<img src={getSocialIcon(handle) || defaultImage} alt={handle} />
              )}
              </a>
            ))}
          </SocialsLinkContainer>

        <StyledSharedContent>
          {data.videos && data.videos.length > 0 && (
            <VideoContainer>
              {data.videos.map((videoUrl, index) => (
                <StyledIframe
                  key={index}
                  src={videoUrl}
                  title={`Video ${index}`}
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  width="400"
                  height="400"
                  frameBorder={0}
                />
              ))}
            </VideoContainer>
          )}

        <StyledDescription>{description}</StyledDescription>

        </StyledSharedContent>


    </ModalContent>
  );
};


const StyledIframe = styled.iframe`
  width: 100%;  // or any specific width
  height: 100%; // or any specific height
`;
const ModalOverlay = styled.div`
  /* Styles for the modal overlay */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 75%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;
const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 450px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border: 1px solid #0AC18E;
  flex-direction: column; /* Stack content vertically */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: green;
  border-radius: 40px 40px 0px 40px;
  padding: 1.5rem;    /* Add padding if needed to prevent content sticking to edges */

  @media (max-width: 832px) { /* You can adjust the breakpoint as needed */
    width: 450px;
    height: 400px;
  }

`;
const StyledLogo = styled.img`
  position: absolute;
  top: -8px;
  left: -8px;
  width: 75px;
  height: 75px;
  border-radius: 180px 180px 180px 180px;
  border: 2px solid #0AC18E;
  background-color: black;
  z-index: 1000;
`;

const StyledIcon = styled.img`
  position: relative;
  width: 25px;
  height: 25px;
  border: 0px solid red;
  &:hover {
    filter: grayscale(0%) sepia(100%) hue-rotate(90deg) saturate(400%); /* change to color */
  }
`;
const StyledWebsite = styled.a`
  font-weight: bold;
  font-size: 26px;
  position: absolute;
  left: 80px;
  top: 0px;
  margin-top: 10px;
  text-decoration: none;
  color: #fff;
  &:hover {
    color: #0AC18E;
  }
`;
const StyledSharedContent = styled.div`
  position: absolute;
  top: 80px;
  height: 80%;
  display: flex;
  flex-direction: column;
  border: 0px solid yellow;
  overflow-y: auto;
  overflow-x: hidden;
`;
const VideoContainer = styled.div`
  border: 0px solid #0AC18E;
  display: flex;
  justify-content: center;
  height: 100%;
  border-radius: 00px 0px 0px 20px;
`;
const StyledDescription = styled.div`
  border: 0px solid #0AC18E;
  width: 100%;
  text-align: left;
  font-size: 16px;
  color: white;
  line-height: 16px;
  margin-bottom: 5px;
  margin-left: 5px;
`; 
const SocialsLinkContainer = styled.div`
  display: flex;
  gap: 4px; /* Adjust the gap between links */
  font-size: 15px;
  position: absolute;
  left: 80px;
  top: 45px;
  justify-content: center;
  i {
    color: #fff;
  }
  i:hover {
    color: #0AC18E;
  }
`;
const CloseButton = styled.button`
  background-image: url(${ImageClose});
  background-size: cover;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: transparent;
  width: 75px;
  height: 45px;
  border: 0px solid #0AC18E;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer;
  display: flex;
  &:hover {
    background-image: url(${ImageCloseHighlight});
  }
`;

export default Modal;
