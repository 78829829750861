


/* { 
  name: '',
  website: '',
  logo: '',
  description: '',
  socialHandles: [''],
  videos: [],
  longerDescription: '',
}, */


 const infoStatsAds =[
  { 
    name: 'Discover.Cash',
    website: 'discover.cash',
    logo: 'discover.png',
    description: 'An attractive new website that lets anyone get up to speed on Bitcoin Cash. From fundamentals to latest developments!',
    socialHandles: ['twitter.com/BCHF_ORG','discord.com/invite/SPRmrfNpDm'],
    videos: [],
    longerDescription: 'The site was created by a collaboration between The Bitcoin Cash Foundation and General Protocols, and will be updated on a quarterly basis to stay fresh and up to date.',
  },
      { 
        name: 'jettscythe.xyz',
        website: 'jettscythe.xyz',
        logo: 'nologo.png',
        description: 'BCH network statistics dashboard maintained by Jett Scythe.',
        socialHandles: [],
        videos: [],
        longerDescription: 'BCH network statistics dashboard maintained by Jett Scythe.',
        tags:[],
      },
      { 
        name: 'Coin Dance',
        website: 'Cash.Coin.Dance',
        logo: 'nologo.png',
        description: 'Community-driven Bitcoin Cash statistics and services.',
        socialHandles: ['twitter.com/Coin_Dance'],
        videos: [],
        longerDescription: 'Community-driven Bitcoin Cash statistics and services.',
        tags:[],
      },
      { 
        name: 'TxStreet',
        website: 'Txstreet.com',
        logo: 'txstreet.png',
        description: 'TxStreet live transaction visualizer. Every transaction is a person, and they fill up buses which represent blocks.',
        socialHandles: ['twitter.com/txstreetcom', 'discord.gg/pFeHv623D7', 't.me/txstreet'],
        videos: [],
        longerDescription: 'TxStreet live transaction visualizer. Every transaction is a person, and they fill up buses which represent blocks.',
        tags:[],
      },
      { 
        name: 'bch101',
        website: 'bch101.com',
        logo: 'bch101.png',
        description: 'BCH101 is an all-in-one introductory site on BCH. It is available for crypto lovers of all ages and levels.',
        socialHandles: ['t.me/coolangels', 'twitter.com/crypto_cindy','discord.gg/sA3D5SVYbQ'],
        videos: [],
        longerDescription: 'BCH101is an all-in-one introductory site on BCH. It is available for crypto lovers of all ages and levels.',
        tags:[],
      },
      { 
        name: 'Reference',
        website: 'Reference.cash',
        logo: 'referencecash.png',
        description: 'Technical information relating to Bitcoin Cash.',
        socialHandles: [],
        videos: [],
        longerDescription: 'Technical information relating to Bitcoin Cash.',
        tags:[],
      },
      { 
        name: 'Documentation.cash',
        website: 'documentation.cash',
        logo: 'bch101.png',
        description: 'The primary goal is for this specification to be the only site a user needs to access to understand the entirety of the Bitcoin Cash protocol',
        socialHandles: [''],
        videos: [],
        longerDescription: 'This documentation is available and kept up to date as a result of the hard work by many different contributors',
      },
      { 
        name: 'Cashnodes',
        website: 'Cashnodes.io',
        logo: 'cashnodes.png',
        description: 'View and search the list of active nodes, their version, countries, network.',
        socialHandles: [],
        videos: [],
        longerDescription: 'View and search the list of active nodes, their version, countries, network.',
        tags:[],
      },
      { 
        name: 'Fork.lol',
        website: 'Fork.lol',
        logo: 'forklol.png',
        description: 'Bitcoin (BTC) and Bitcoin (BCH) network statistics.',
        socialHandles: [],
        videos: [],
        longerDescription: 'Bitcoin (BTC) and Bitcoin (BCH) network statistics.',
        tags:[],
      },
      { 
        name: 'Johoe`s Bitcoin Mempool Stats',
        website: 'mempool.jhoenicke.de/#BCH,24h,weight',
        logo: 'johoe.png',
        description: 'Mempool graphs for BCH, BTC, ETH, LTC, DOGE, DASH',
        socialHandles: ['github.com/jhoenicke/mempool'],
        videos: [],
        longerDescription: 'Mempool graphs for BCH, BTC, ETH, LTC, DOGE, DASH',
        tags:[],
      },
      { 
        name: 'Bitcoin Fees',
        website: 'bitcoinfees.cash',
        logo: 'bitcoinfees.png',
        description: 'BCH vs BTC fees, both current and historical.',
        socialHandles: ['twitter.com/bitcoinfeescash', 't.me/bitcoinfeescash', 'reddit.com/r/BitcoinTxFees'],
        videos: [],
        longerDescription: 'Bitcoin transaction fees are included with any Bitcoin transaction so that it can be validated by a Bitcoin miner which then in turn gets the transaction confirmed on the blockchain. Once a transaction is confirmed on the blockchain, it is irreversible. Transaction fees are part of the incentives for miners to mine the Bitcoin blockchain. The other part of incentives for miners is the block reward for each mined block..',
        tags:[],
      },
      { 
        name: 'whybitcoincash',
        website: 'whybitcoincash.com',
        logo: 'bch.png',
        description: 'Compares BCH vs BTC. Join the digital revolution.',
        socialHandles: [],
        videos: [],
        longerDescription: 'Compares BCH vs BTC. Join the digital revolution.',
        tags:[],
      },
      { 
        name: 'Bch.info',
        website: 'bch.info/en/',
        logo: 'bch101.png',
        description: 'A resource website on BCH.',
        socialHandles: ['github.com/bchinfo/bch.info'],
        videos: [],
        longerDescription: '',
        tags:[],
      },
      { 
        name: 'Bitcoincash.org',
        website: 'bitcoincash.org/',
        logo: 'bch.png',
        description: 'Information and resource website on BCH.',
        socialHandles: ['github.com/zquestz/bitcoincash'],
        videos: [],
        longerDescription: '',
        tags:[],
      },
      { 
        name: 'Awesomebitcoin',
        website: 'awesomebitcoin.cash/',
        logo: 'awesomebitcoin.png',
        description: 'A curated list of Bitcoin Cash projects & resource.',
        socialHandles: ['github.com/2qx/awesome-bitcoin-cash'],
        videos: [],
        longerDescription: '',
        tags:[],
      },
      { 
        name: 'Bitcoincashsite',
        website: 'bitcoincashsite.com/',
        logo: 'bitcoincashsite.png',
        description: 'A Bitcoin Cash information website.',
        socialHandles: ['youtube.com/@RealBitcoinCashSite','twitter.com/bitcoincashsite','t.me/bitcoincashsite','github.com/Panmoni/bitcoincashsite-www','instagram.com/bitcoincashsite/'],
        videos: [],
        longerDescription: 'The Bitcoin Cash Site was founded by George Donnelly in August 2020 to serve the goal of onboarding 1 million new Bitcoin Cash Users.',
        tags:[],
      },
      { 
        name: 'Crypto Bakery',
        website: 'holdbch.wixsite.com/cryptobakery',
        logo: 'cryptobakery.png',
        description: 'An education website about BCH for the Spanish speaking community',
        socialHandles: ['twitter.com/CryptoBakeryVe','read.cash/@ramonoropeza','instagram.com/cryptobakeryve'],
        videos: [], 
       longerDescription: 'In its first phase it is dedicated to dissemination and education about BCH to the Spanish-speaking community.',
       tags:['Spanish'],
      },
      { 
        name: 'Bitcoin Cash Argentina',
        website: 'bitcoincashargentina.com',
        logo: 'argentina.png',
        description: ' An education website focused on Argentina BCH adoption.',
        socialHandles: ['t.me/BCHArgentina','read.cash/@BitcoinCashArgentina','instagram.com/bitcoincashargentina/','twitter.com/BCHArgentina','odysee.com/@bitcoincashargentina:4'],
        videos: [],
        longerDescription: '',
        tags:['Spanish'],
      },
      { 
        name: 'Tw Bitcoin',
        website: 'twbitcoin.cash',
        logo: 'taiwanbitcoin.png',
        description: 'Taiwan information and news site.',
        socialHandles: ['t.me/twbitcoincash','twitter.com/twbitcoincash'],
        videos: [],
        longerDescription: '',
        tags:['Mandarin'],
      },
      { 
        name: 'Bitcoin Protocol',
        website: 'bitcoinprotocol.org/',
        logo: 'bitcoinprotocol.png',
        description: 'A Bitcoin Cash information website',
        socialHandles: ['twitter.com/davidshares'],
        videos: [],
        longerDescription: 'The Bitcoin Protocol landing page was founded by David Shares',
        tags:[],
      }, 
      { 
        name: 'BCH HUB',
        website: 'hub-bch.com',
        logo: 'bchhub.png',
        description: 'Nigerias BCH HUB: Fusing social capital & tech for economic prosperity. Powered by BitcoinCash.',
        socialHandles: ['twitter.com/bchhub_ng'],
        videos: ["https://www.youtube.com/embed/APXXtrPdreU"],
        longerDescription: 'We believe in the transformative power of technology to shape a brighter future.',
        tags:[],
      }, 
      { 
        name: 'BCH PLS',
        website: 'bchpls.org',
        logo: 'bchdark.png',
        description: 'German Information news page about BCH',
        socialHandles: [''],
        videos: [],
        longerDescription: '',
        tags:['German'],
      }, 
      { 
        name: 'BCH For Everyone',
        website: 'bchforeveryone.net',
        logo: 'bchdark.png',
        description: 'Informational news page about BCH',
        socialHandles: [''],
        videos: [],
        longerDescription: '',
        tags:[],
      }, 
      { 
        name: 'BitcoinCash Standards',
        website: 'bitcoincashstandards.org',
        logo: 'bchstandard.png',
        description: 'An overview of standards in Bitcoin Cash',
        socialHandles: [''],
        videos: [],
        longerDescription: '',
        tags:[],
      }, 
    ]

export default infoStatsAds